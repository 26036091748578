import { FC } from 'react';
import styles from './capital-structure.module.scss';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import Button from '@app/shared/components/button/Button';
import Sidebar from '@app/shared/components/sidebar/Sidebar';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { getProjectDraftWithRemovedInstrument } from '@app/shared/helpers';
import SvgTrash from '@app/shared/icons/Trash';
import { EventKey, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import CapitalStructureItemFormErf from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemFormErf';
import { useParams } from 'react-router-dom';
import {
  EquityRefinancingEventOwnerMovementDto,
  EquityRefinancingEventProRataMovementDto,
} from '@app/shared/models/contracts/project-dto';
import { calculateBuildStructure } from '@app/core/store/capital-structure-slice';

const PARENT_CLASSNAME = 'capital-structure';

const CapitalStructureSidebarErf: FC = (): JSX.Element | null => {
  const { caseId } = useParams();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const activeSelectedInstrument = useAppSelector((state) => state.uiValues.activeInstrument);
  const capitalStructures = project.capitalStructures;
  const capitalStructureKey = Object.keys(capitalStructures)?.[0];
  const capitalStructure = project.capitalStructures[capitalStructureKey];
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  if (!buildStructures) {
    return null;
  }
  const buildStructure = buildStructures[capitalStructureKey];
  const isErfProject = buildStructures?.[capitalStructureKey].isErf;

  const isEvent = !(activeSelectedInstrument?.eventId === EventKey.InitialCapitalStructure);

  const eventSetId =
    project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId)?.eventSetId ??
    EventKey.EmptyEventSet;
  const selectedEvent = buildStructure.eventSets[eventSetId]?.events.find(
    (event) => event.id === activeSelectedInstrument?.eventId
  );

  if (!activeSelectedInstrument?.instrumentId) {
    return null;
  }

  const instrumentDefinition =
    capitalStructure.instrumentDefinitions[activeSelectedInstrument.instrumentId];
  const initialValues = capitalStructure.initialValues[activeSelectedInstrument.instrumentId];
  const selectedInstrument = selectedEvent?.ranks
    .flatMap((rank) => Object.entries(rank.instruments))
    .find(([instrumentId, _]) => {
      return instrumentId === activeSelectedInstrument.instrumentId;
    })?.[1];

  if (!instrumentDefinition || !selectedInstrument) {
    return null;
  }

  const getEventValues = () => {
    let couponValue = undefined;
    let couponStartDate = null;
    let ownerMovements: Nullable<Record<string, EquityRefinancingEventOwnerMovementDto>> = null;
    let proRataMovement: Nullable<EquityRefinancingEventProRataMovementDto> = null;
    if (
      activeSelectedInstrument.instrumentId &&
      selectedEvent &&
      capitalStructure.events[selectedEvent.id]
    ) {
      const movements = capitalStructure.events[selectedEvent.id].movements;
      const instrumentId = activeSelectedInstrument.instrumentId;

      if (Object.keys(movements).includes(instrumentId)) {
        const instrumentMovements = movements[instrumentId];

        couponValue = instrumentMovements?.coupon?.value;
        couponStartDate = instrumentMovements?.coupon?.date;
        ownerMovements = instrumentMovements?.ownerMovements;
        proRataMovement = instrumentMovements?.proRataMovement ?? null;
      }
    }

    return {
      ...selectedInstrument,
      ...instrumentDefinition,
      // this is required because this can be null in the capitalStructure but needs a defined value in the form
      isSweetEquity: instrumentDefinition.isSweetEquity ?? false,
      proRataAmount: proRataMovement?.amount ?? null,
      proRataShares: proRataMovement?.numberOfShares ?? null,
      instrumentId: activeSelectedInstrument.instrumentId!,
      couponValue: couponValue ? couponValue * 100 : null,
      couponStartDate,
      ownership: Object.keys(OwnerType).map((owner) => {
        return {
          owner: owner as keyof typeof OwnerType,
          amount:
            ownerMovements && Object.keys(ownerMovements).includes(owner)
              ? ownerMovements[owner]?.amount
              : null,
          numberOfShares:
            ownerMovements && Object.keys(ownerMovements).includes(owner)
              ? ownerMovements[owner]?.numberOfShares
              : null,
          currentAmount: selectedInstrument.ownershipAfterMovements
            ? selectedInstrument.ownershipAfterMovements[owner]?.amount
            : null,
          currentNumberOfShares: selectedInstrument.ownershipAfterMovements
            ? selectedInstrument.ownershipAfterMovements[owner]?.numberOfShares
            : null,
        };
      }),
    };
  };

  const getInitialStructureValues = () => {
    const couponValue = Object.keys(capitalStructure.initialValues).includes(
      activeSelectedInstrument.instrumentId!
    )
      ? capitalStructure.initialValues[activeSelectedInstrument.instrumentId!].coupon?.value
      : undefined;
    return {
      ...selectedInstrument,
      ...instrumentDefinition,
      // this is required because this can be null in the capitalStructure but needs a defined value in the form
      isSweetEquity: instrumentDefinition.isSweetEquity ?? false,
      instrumentId: activeSelectedInstrument.instrumentId!,
      couponValue: couponValue ? couponValue * 100 : undefined,
      ownership: Object.keys(OwnerType).map((owner) => {
        return {
          owner: owner as keyof typeof OwnerType,
          amount: initialValues?.ownership[owner]?.amount ?? null,
          numberOfShares: initialValues?.ownership[owner]?.numberOfShares ?? null,
          currentAmount: null,
          currentNumberOfShares: null,
        };
      }),
    };
  };

  const getValues = () => {
    const values = isEvent ? getEventValues() : getInitialStructureValues();
    return values;
  };

  const equityItem = getValues();

  const removeInstrument = async (instrumentId: string | undefined) => {
    if (instrumentId) {
      await dispatch(
        calculateBuildStructure({
          project: getProjectDraftWithRemovedInstrument({ draft: project, instrumentId }),
          ignoreErrors: true,
        })
      );

      dispatch(uiValuesSlice.actions.setActiveInstrument(undefined));
    }
  };

  return (
    <>
      {selectedInstrument && !isErfProject && (
        <Sidebar
          data-testid="instrument-details-sidebar"
          actions={
            <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
              <Button
                onClick={() => removeInstrument(activeSelectedInstrument?.instrumentId)}
                size={ButtonSize.FULL_WIDTH}
                startIcon={<SvgTrash />}
                appearance={ButtonAppearance.DEFAULT_SECONDARY}>
                Delete Instrument
              </Button>
            </div>
          }>
          <CapitalStructureItemFormErf equityItem={equityItem} eventSetId={eventSetId} />
        </Sidebar>
      )}
    </>
  );
};

export default CapitalStructureSidebarErf;
