import { useAppSelector } from '@app/core/hooks/redux-hooks';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { numericBaseFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useLocale } from '@app/core/hooks/useLocale';
import { getRequiredValidator } from '@app/core/validations/hook-forms/validators';

const OperationalFreeCashFlowSection2: FC = (): JSX.Element => {
  const { caseId } = useParams();
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const pwermInputResults = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const numberOfResultsForecastYears = Object.keys(
    pwermInputResults.cases[caseId!].forecastDates
  ).length;
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseDataForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);
  const historicData = project.pwermInput.historicForecasts;
  const numberOfInputHistoricYears = historicData.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicDataResults = Object.keys(results.cases[caseId!].historicDates)
    .slice(0, numberOfInputHistoricYears)
    .reverse();
  const numberOfResultsHistoricYears = historicDataResults.length;
  const maxColspanCount =
    (caseData?.forecasts ? caseData?.forecasts.length : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    numberOfResultsHistoricYears;
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  return (
    <>
      <tbody>
        <tr>
          <th className="table-primary__cell--header" colSpan={maxColspanCount}>
            {l('_OperationalFreeCashFlowTitle')}
          </th>
        </tr>
        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--vertical-separator">
            {l('_OperationalFreeCashFlow')}
          </th>
          {showHistoricDataColumns &&
            historicDataResults.map((data, index) => (
              <td key={data}>
                <GridTextField
                  name={`historicForecasts.${
                    numberOfInputHistoricYears - index - 1
                  }.operationalFreeCashFlow`}
                  alignment={Alignment.Right}
                  {...numericBaseFieldFormattingProps}
                  placeholder="0"
                />
              </td>
            ))}
          <td />
          {caseDataForecasts?.map((_, index) => (
            <td key={index + 'cash-flow'}>
              <GridTextField
                name={`forecasts.${index}.inputs.operationalFreeCashFlow`}
                alignment={Alignment.Right}
                {...numericBaseFieldFormattingProps}
                rules={{
                  ...getRequiredValidator(),
                }}
                placeholder="0"
              />
            </td>
          ))}
          <td />
        </tr>
      </tbody>
    </>
  );
};

export default OperationalFreeCashFlowSection2;
