import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { FC, Fragment } from 'react';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import {
  positiveValueValidator,
  getRequiredValidator,
} from '@core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { selectSlnAndPrefSharesInstruments } from '@core/store/project-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { GridDatepickerField } from '@app/shared/components/grid-controls/grid-datepicker-field/GridDatepickerField';
import { OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { isInstrumentOwnerAmountEntered } from '@app/shared/helpers';
import {
  DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT,
  DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT,
} from '@app/shared/constants/table-config';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis/DealThesisPage';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getDealThesisInputCaseForecastDateShareholderDebtInstrumentsOwner,
  getDealThesisInputCaseValuationDateShareholderDebtInstrumentsOwner,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';

interface DealThesisCaseSlnAndPrefShareSectionProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseSlnAndPrefShareSection2: FC<
  DealThesisCaseSlnAndPrefShareSectionProps
> = ({ handleDataSubmit }) => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const numberOfResultsForecastYears = Object.keys(results.cases[caseId!].forecastDates).length;
  const shareholderInstruments = results.cases[caseId!].valuationDate.shareholderDebtInstruments;
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);
  const historicData = project.pwermInput.historicForecasts;
  const numberOfInputHistoricYears = historicData.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicResultsData = Object.values(results.cases[caseId!].historicDates)
    .slice(0, numberOfInputHistoricYears)
    .reverse();
  const numberOfResultsHistoricYears = historicResultsData.length;
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);
  const { l } = useLocale();
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  const { control } = useFormContext<DealThesisFormModel>();
  const { fields } = useFieldArray({
    name: 'filteredSlnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (numberOfResultsForecastYears ?? 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    numberOfResultsHistoricYears;

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEntered
  );

  return !filteredSlnAndPrefSharesInstruments.length ? (
    <></>
  ) : (
    <>
      <tbody>
        <tr className="table-primary__row--plain">
          <td colSpan={sectionColCount} className="table-primary__cell--header">
            {l('_ShareholderDebtInstruments')}
          </td>
        </tr>
        {fields.map((instrument, instrumentIndex) => (
          <Fragment key={instrument.id}>
            <tr className="table-primary__row--header">
              <th
                colSpan={sectionColCount}
                className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                {instrument.instrumentNarrative}
              </th>
            </tr>
            <tr className="table-primary__row--plain">
              <th className="table-primary__cell--vertical-separator">{l('_CouponStartDate')}</th>
              {showHistoricDataColumns && historicResultsData.map((_, index) => <td key={index} />)}
              <td>
                <GridDatepickerField
                  name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponStartDate`}
                  rules={{
                    ...getRequiredValidator(),
                  }}
                  formSubmitHandler={handleDataSubmit}
                />
              </td>
              <td
                colSpan={
                  (numberOfResultsForecastYears ?? 0) + DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT
                }
              />
            </tr>
            <tr className="table-primary__row--plain">
              <th className="table-primary__cell--vertical-separator">{l('_CouponPc')}</th>
              {showHistoricDataColumns && historicResultsData.map((_, index) => <td key={index} />)}
              <td>
                <GridTextField
                  name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponValue`}
                  {...percentageFieldFormattingProps}
                  data-testid={slugifyStrings(
                    instrument.instrumentNarrative,
                    'dealthesis-coupon-val'
                  )}
                  rules={{
                    ...getRequiredValidator(),
                    ...positiveValueValidator(true),
                  }}
                  alignment={Alignment.Right}
                />
              </td>
              <td
                colSpan={
                  (numberOfResultsForecastYears ?? 0) + DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT
                }
              />
            </tr>
            {Object.keys(shareholderInstruments[instrument.instrumentId].closingBalanceByOwner).map(
              (owner, index) => {
                return (
                  <Fragment key={index}>
                    <tr className="table-primary__row--secondary">
                      <th
                        colSpan={
                          (numberOfResultsForecastYears ?? 0) +
                          DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
                          numberOfResultsHistoricYears
                        }
                        className="table-primary__cell--indentation table-primary__cell--strong">
                        {OwnerType[owner as keyof typeof OwnerType]}
                      </th>
                    </tr>
                    <tr>
                      <th className="table-primary__cell--vertical-separator table-primary__cell--indentation">
                        {l('_ClosingBalance')}
                      </th>
                      {showHistoricDataColumns &&
                        historicResultsData.map((_, index) => <td key={index} />)}
                      <td>
                        <CellValue
                          {...amountRules}
                          value={getDealThesisInputCaseValuationDateShareholderDebtInstrumentsOwner(
                            results,
                            caseId!,
                            instrument.instrumentId,
                            owner as OwnerType
                          )}
                        />
                      </td>
                      {caseForecasts?.map((forecast, index) => (
                        <td key={index}>
                          <CellValue
                            {...amountRules}
                            value={getDealThesisInputCaseForecastDateShareholderDebtInstrumentsOwner(
                              results,
                              caseId!,
                              forecast.forecastYear,
                              instrument.instrumentId,
                              owner as OwnerType
                            )}
                          />
                        </td>
                      ))}
                      <td />
                    </tr>
                  </Fragment>
                );
              }
            )}
            <DealThesisSpacerRow2 />
          </Fragment>
        ))}
      </tbody>
    </>
  );
};
