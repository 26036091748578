import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { FC, Fragment, useState } from 'react';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import {
  positiveValueValidator,
  getRequiredValidator,
} from '@core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { selectSlnAndPrefSharesInstruments } from '@core/store/project-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { GridDatepickerField } from '@app/shared/components/grid-controls/grid-datepicker-field/GridDatepickerField';
import { ViewChangeType } from '@app/shared/models/contracts/enums/shared-enums';
import { isInstrumentOwnerAmountEntered } from '@app/shared/helpers';
import {
  DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT,
  DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT,
} from '@app/shared/constants/table-config';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis/DealThesisPage';
import { DealThesisSpacerRow } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisSpacerRow';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto,
  Pwerm2InputYearDebtInstrumentCalculationResultsPeriodTotalDto,
} from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';
import SvgAnalysis from '@app/shared/icons/Analysis';
import styles from './deal-thesis-corkscrew-modal.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { CorkscrewModal } from './CorkscrewModal';
import { translationKeys } from 'src/locale/setupI18n';
import { mapCouponTypeInterestToTranslation } from '../../deal-thesis-utils/CouponInterestTranslationHelper';

interface DealThesisCaseSlnAndPrefShareSectionProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseSlnAndPrefShareSectionERF: FC<
  DealThesisCaseSlnAndPrefShareSectionProps
> = ({ handleDataSubmit }) => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);
  const { l } = useLocale();

  const [isCorkscrewModalOpen, setIsCorkscrewModalOpen] = useState(false);
  const [instrumentId, setInstrumentId] = useState('');
  const [instrumentNarrative, setInstrumentNarrative] = useState('');
  const [eventData, setEventData] = useState<
    Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto[]
  >([]);
  const [shouldHidePrevious, setShouldHidePrevious] = useState(true);
  const [shouldHideNext, setShouldHideNext] = useState(true);

  const { control } = useFormContext<DealThesisFormModel>();
  const { fields } = useFieldArray({
    name: 'filteredSlnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (caseData?.forecasts.length ?? 0) + DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  // TODO: this will include the number of historical years once that has been implemented
  const preForecastColumnCount = 1;

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEntered
  );

  const determineCorkscrewNavigationButtonVisibility = (instrumentId: string, date: string) => {
    const allDates = Object.keys(results.cases[caseId!].forecastDates);
    allDates.unshift(project.valuationDate);
    const firstDateWithMultipleEvents = allDates.find((date) =>
      Object.keys(results.cases[caseId!].forecastDates).includes(date)
        ? results.cases[caseId!].forecastDates[date].shareholderDebtInstrumentsEvents[instrumentId]
            .periodByEvent.length > 1
        : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[instrumentId]
            .periodByEvent.length > 1
    );
    const lastDateWithMultipleEvents = allDates
      .slice()
      .reverse()
      .find((date) =>
        Object.keys(results.cases[caseId!].forecastDates).includes(date)
          ? results.cases[caseId!].forecastDates[date].shareholderDebtInstrumentsEvents[
              instrumentId
            ].periodByEvent.length > 1
          : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[instrumentId]
              .periodByEvent.length > 1
      );
    const isForecastDateFirstDateWithMultipleEvents = date === firstDateWithMultipleEvents;
    const isForecastDateLastDateWithMultipleEvents = date === lastDateWithMultipleEvents;

    return {
      shouldHidePrevious: isForecastDateFirstDateWithMultipleEvents,
      shouldHideNext: isForecastDateLastDateWithMultipleEvents,
    };
  };

  const handleCorkscrewButtonClick = (
    isOpen: boolean,
    instrumentId: string,
    instrumentNarrative: string,
    eventData: Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto[],
    forecastDate: string
  ) => {
    const { shouldHidePrevious, shouldHideNext } = determineCorkscrewNavigationButtonVisibility(
      instrumentId,
      forecastDate
    );
    setShouldHidePrevious(shouldHidePrevious);
    setShouldHideNext(shouldHideNext);
    setIsCorkscrewModalOpen(isOpen);
    setInstrumentId(instrumentId);
    setInstrumentNarrative(instrumentNarrative);
    setEventData(eventData);
  };

  const handleCorkscrewForecastChange = (
    instrumentId: string,
    changeType: keyof typeof ViewChangeType
  ) => {
    const allDates = Object.entries(results.cases[caseId!].forecastDates);
    allDates.unshift([project.valuationDate, results.cases[caseId!].valuationDate]);
    const currentEventForecastDate = allDates.find(
      ([_, values]) =>
        values.shareholderDebtInstrumentsEvents[instrumentId].periodByEvent === eventData
    )?.[0];
    const currentEventIndex = allDates.findIndex(([date]) => date === currentEventForecastDate);
    if (changeType === ViewChangeType.Next) {
      const nextEventIndex = allDates
        .slice(currentEventIndex + 1)
        .findIndex(
          ([, values]) =>
            values.shareholderDebtInstrumentsEvents[instrumentId].periodByEvent.length > 1
        );

      if (nextEventIndex !== -1) {
        const nextEventDate = allDates[currentEventIndex + 1 + nextEventIndex][0];
        const nextEventData = Object.keys(results.cases[caseId!].forecastDates).includes(
          nextEventDate
        )
          ? results.cases[caseId!].forecastDates[nextEventDate].shareholderDebtInstrumentsEvents[
              instrumentId
            ].periodByEvent
          : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[instrumentId]
              .periodByEvent;
        setEventData(nextEventData);
        const { shouldHidePrevious, shouldHideNext } = determineCorkscrewNavigationButtonVisibility(
          instrumentId,
          nextEventDate
        );
        setShouldHidePrevious(shouldHidePrevious);
        setShouldHideNext(shouldHideNext);
      }
    } else {
      const previousEventIndex = allDates
        .slice(0, currentEventIndex)
        .reverse()
        .findIndex(
          ([, values]) =>
            values.shareholderDebtInstrumentsEvents[instrumentId].periodByEvent.length > 1
        );

      if (previousEventIndex !== -1) {
        const previousEventDate = allDates[currentEventIndex - 1 - previousEventIndex][0];
        const previousEventData = Object.keys(results.cases[caseId!].forecastDates).includes(
          previousEventDate
        )
          ? results.cases[caseId!].forecastDates[previousEventDate]
              .shareholderDebtInstrumentsEvents[instrumentId].periodByEvent
          : results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[instrumentId]
              .periodByEvent;
        setEventData(previousEventData);
        const { shouldHidePrevious, shouldHideNext } = determineCorkscrewNavigationButtonVisibility(
          instrumentId,
          previousEventDate
        );
        setShouldHidePrevious(shouldHidePrevious);
        setShouldHideNext(shouldHideNext);
      }
    }
  };

  const createTableRow = (
    title: translationKeys,
    key: keyof Pwerm2InputYearDebtInstrumentCalculationResultsPeriodTotalDto,
    instrumentId: string,
    isBold = false,
    isPercentage = false,
    isText = false
  ) => {
    return (
      <tr>
        <th className="table-primary__cell--vertical-separator">
          {isBold ? <strong>{title}</strong> : title}
        </th>
        <td />
        {Object.values(results.cases[caseId!].forecastDates).map((forecast, index) => {
          return (
            <td key={index}>
              <CellValue
                value={
                  key === 'couponType'
                    ? mapCouponTypeInterestToTranslation(
                        forecast.shareholderDebtInstrumentsEvents[instrumentId]?.periodTotal
                          .couponType
                      )
                    : forecast.shareholderDebtInstrumentsEvents[instrumentId]?.periodTotal[key]
                }
                {...(isText ? {} : amountRules)}
                {...(isPercentage ? enteredPercentageViewRules : {})}
                strong={isBold}
                alignment={Alignment.Right}
              />
            </td>
          );
        })}
        <td />
      </tr>
    );
  };

  return !filteredSlnAndPrefSharesInstruments.length ? (
    <></>
  ) : (
    <>
      <tbody>
        <tr className="table-primary__row--plain">
          <td colSpan={sectionColCount} className="table-primary__cell--header">
            {l('_ShareholderDebtInstruments')}
          </td>
        </tr>
        {fields.map((instrument, instrumentIndex) => (
          <Fragment key={instrument.id}>
            <tr className="table-primary__row--header">
              <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                {instrument.instrumentNarrative}
              </th>
              <td
                colSpan={preForecastColumnCount}
                className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                {results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[
                  instrument.instrumentId
                ]?.periodByEvent.length > 1 && (
                  <Button
                    className={styles['corkscrew-modal-icon']}
                    startIcon={<SvgAnalysis />}
                    onClick={() =>
                      handleCorkscrewButtonClick(
                        true,
                        instrument.instrumentId,
                        instrument.instrumentNarrative,
                        results.cases[caseId!].valuationDate.shareholderDebtInstrumentsEvents[
                          instrument.instrumentId
                        ]?.periodByEvent,
                        project.valuationDate
                      )
                    }
                    autoIconSize
                    appearance={ButtonAppearance.CLEAN}
                    size={ButtonSize.SMALL}
                  />
                )}
              </td>
              {Object.entries(results.cases[caseId!].forecastDates).map(
                ([forecastDate, forecastValues], index) => {
                  return (
                    <td
                      key={index}
                      className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                      {forecastValues.shareholderDebtInstrumentsEvents[instrument.instrumentId]
                        ?.periodByEvent.length > 1 && (
                        <Button
                          className={styles['corkscrew-modal-icon']}
                          startIcon={<SvgAnalysis />}
                          onClick={() =>
                            handleCorkscrewButtonClick(
                              true,
                              instrument.instrumentId,
                              instrument.instrumentNarrative,
                              forecastValues.shareholderDebtInstrumentsEvents[
                                instrument.instrumentId
                              ].periodByEvent,
                              forecastDate
                            )
                          }
                          autoIconSize
                          appearance={ButtonAppearance.CLEAN}
                          size={ButtonSize.SMALL}
                        />
                      )}
                    </td>
                  );
                }
              )}
              <td className="table-primary__cell--header-secondary table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
            </tr>
            <tr className="table-primary__row--plain">
              <th className="table-primary__cell--vertical-separator">{l('_CouponStartDate')}</th>
              <td>
                <GridDatepickerField
                  name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponStartDate`}
                  rules={{
                    ...getRequiredValidator(),
                  }}
                  formSubmitHandler={handleDataSubmit}
                />
              </td>
              <td
                colSpan={
                  (caseData?.forecasts.length ?? 0) + DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT
                }
              />
            </tr>
            <tr className="table-primary__row--plain">
              <th className="table-primary__cell--vertical-separator">{l('_CouponPc')}</th>
              <td>
                <GridTextField
                  name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponValue`}
                  {...percentageFieldFormattingProps}
                  data-testid={slugifyStrings(
                    instrument.instrumentNarrative,
                    'dealthesis-coupon-val'
                  )}
                  rules={{
                    ...getRequiredValidator(),
                    ...positiveValueValidator(true),
                  }}
                  alignment={Alignment.Right}
                />
              </td>
              <td
                colSpan={
                  (caseData?.forecasts.length ?? 0) + DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT
                }
              />
            </tr>
            {createTableRow(l('_OpeningBalance'), 'openingBalance', instrument.instrumentId, true)}
            {createTableRow(l('_AdditionOrRedemption'), 'additions', instrument.instrumentId)}
            {createTableRow(l('_AccruedInterest'), 'accruedInterest', instrument.instrumentId)}
            {createTableRow(l('_ClosingBalance'), 'closingBalance', instrument.instrumentId, true)}
            {createTableRow(l('_CouponPc'), 'couponPc', instrument.instrumentId, false, true)}
            {createTableRow(
              l('_CouponType'),
              'couponType',
              instrument.instrumentId,
              false,
              false,
              true
            )}
            {createTableRow(
              l('_ProportionOfCashPay'),
              'cashPayProportionPc',
              instrument.instrumentId,
              false,
              true
            )}
            {createTableRow(
              l('_CashPayInterest'),
              'cashPayInterest',
              instrument.instrumentId,
              false,
              false
            )}
            <DealThesisSpacerRow />
          </Fragment>
        ))}
      </tbody>
      <CorkscrewModal
        isOpen={isCorkscrewModalOpen}
        setIsOpen={setIsCorkscrewModalOpen}
        instrumentId={instrumentId}
        instrumentNarrative={instrumentNarrative}
        eventData={eventData}
        shouldHidePrevious={shouldHidePrevious}
        shouldHideNext={shouldHideNext}
        handleCorkscrewForecastChange={handleCorkscrewForecastChange}
      />
    </>
  );
};
