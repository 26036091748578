import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  positiveValueValidator,
  getRequiredValidator,
} from '@core/validations/hook-forms/validators';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import SvgChevron from '@app/shared/icons/Chevron';
import { FC, Fragment } from 'react';
import styles from '../../../opm-inputs.module.scss';
import useLocalStorageCollapseState from '@app/core/hooks/useLocalStoreCollapse';
import { GridDatepickerField } from '@app/shared/components/grid-controls/grid-datepicker-field/GridDatepickerField';
import { OpmInputFormModel } from '@app/modules/projects/inputs/opm-inputs/OpmInputs';
import {
  OPM_TABLE_DATA_ADDITIONAL_COL_COUNT,
  OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT,
} from '@app/shared/constants/table-config';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import SvgSync from '@app/shared/icons/Sync';
import { useLocale } from '@app/core/hooks/useLocale';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { SummaryShareholderDebt } from './components/SummaryShareholderDebt';
import { getEquityInstrumentOwnerships } from '@app/shared/helpers/equity-instruments/get-equity-instrument-ownerships';
import {
  CalcMethod,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import { enumKeyByValue } from '@app/shared/helpers';

interface ShareholderDebtSectionProps {
  submitData: () => void;
}

export const ShareholderDebtSection: FC<ShareholderDebtSectionProps> = ({
  submitData,
}): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const opmInput = { ...project.opmInput };
  const capitalStructure = useAppSelector((state) => state.capitalStructure.values);
  const { control } = useFormContext<OpmInputFormModel>();
  const { fields } = useFieldArray({
    name: 'filteredSlnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (opmInput?.perYearInputs.length ?? 0) + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const [expanded, setExpanded] = useLocalStorageCollapseState(
    'expanded-shareholder-debt',
    project.id
  );

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const maxColspanCount =
    project.opmInput.forecastYears !== null
      ? project.opmInput.forecastYears + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
      : 0;

  const showShareholderDebtSection = project.equityInstruments.some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares) ||
      i.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );

  return showShareholderDebtSection && capitalStructure.debtInstrumentsByYear ? (
    <>
      <tbody>
        <tr data-testid="sd-title-section">
          <th className={`table-primary__cell--header-tertiary ${styles[`opm-header`]}`}>
            <div className={styles['opm-collapsable-header']}>
              <button
                type="button"
                aria-label={l('_ToggleSectionCollapse', {
                  section: l('_ShareholderDebtTitle'),
                })}
                aria-expanded={expanded}
                style={{ border: 'none', backgroundColor: 'none', background: 'none' }}
                onClick={toggleCollapse}
                data-testid="sd-collapse-button">
                {!expanded ? (
                  <SvgChevron
                    onClick={toggleCollapse}
                    className={styles[`opm-chevron-collapsed`]}
                  />
                ) : (
                  <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
                )}
              </button>
              {l('_ShareholderDebtTitle')}
            </div>
          </th>
          <th
            className={`table-primary__cell--header-tertiary ${styles[`opm-header-badge`]}`}
            colSpan={maxColspanCount - 1}>
            {project.details.calcMethod !== CalcMethod.OPM && (
              <div className={styles['opm-collapsable-header-badge']} data-testid="sd-badge">
                <Pill size={PillSize.Xsmall} noTextTransform theme={PillTheme.White}>
                  {l(`_Synced`)}
                </Pill>
              </div>
            )}
          </th>
        </tr>
        {expanded && (
          <>
            <SummaryShareholderDebt />
            {fields.length > 0 && (
              <tr className="table-primary__row--plain">
                <td colSpan={sectionColCount} className="table-primary__cell--header">
                  {l('_ShareholderDebtInstruments')}
                </td>
              </tr>
            )}
            {fields.map((instrument: EquityInstrumentDto, instrumentIndex) => (
              <Fragment key={instrument.instrumentId}>
                <tr className="table-primary__row--header">
                  <th
                    colSpan={sectionColCount}
                    className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                    {instrument.instrumentNarrative}
                  </th>
                </tr>
                <tr className="table-primary__row--plain">
                  <th className={`table-primary__cell--vertical-separator ${styles[`sync-icon`]}`}>
                    <p>{l('_CouponStartDate')}</p>
                    <div className={styles[`sync-icon-div`]}>
                      <SvgSync className={styles[`sync-icon-style`]} />
                    </div>
                  </th>
                  <td>
                    <GridDatepickerField
                      name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponStartDate`}
                      formSubmitHandler={submitData}
                      rules={{
                        ...getRequiredValidator(),
                      }}
                    />
                  </td>
                  <td
                    colSpan={
                      (project.opmInput.forecastYears ?? 0) + OPM_TABLE_DATA_ADDITIONAL_COL_COUNT
                    }
                  />
                </tr>
                <tr className="table-primary__row--plain">
                  <th className={`table-primary__cell--vertical-separator ${styles[`sync-icon`]}`}>
                    <p>{l('_CouponPc')}</p>
                    <div className={styles[`sync-icon-div`]}>
                      <SvgSync className={styles[`sync-icon-style`]} />
                    </div>
                  </th>
                  <td>
                    <GridTextField
                      name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponValue`}
                      {...percentageFieldFormattingProps}
                      rules={{
                        ...positiveValueValidator(true),
                        ...getRequiredValidator(),
                      }}
                      data-testid={slugifyStrings(instrument.instrumentNarrative, 'coupon-val')}
                      alignment={Alignment.Right}
                    />
                  </td>
                  <td
                    colSpan={
                      (project.opmInput.forecastYears ?? 0) + OPM_TABLE_DATA_ADDITIONAL_COL_COUNT
                    }
                  />
                </tr>
                {getEquityInstrumentOwnerships(instrument, (x) => x.amount).map((owner, index) => (
                  <Fragment key={index}>
                    <tr className="table-primary__row--secondary">
                      <th
                        colSpan={
                          (project.opmInput.forecastYears ?? 0) +
                          OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
                        }
                        className="table-primary__cell--indentation table-primary__cell--strong">
                        {OwnerType[owner.owner]}
                      </th>
                    </tr>
                    <tr>
                      <th className="table-primary__cell--vertical-separator table-primary__cell--indentation">
                        {l('_ClosingBalance')}
                      </th>
                      <td>
                        <CellValue {...amountRules} value={owner.amount} />
                      </td>
                      {project.opmInput.perYearInputs?.map((forecast, index) => (
                        <td key={index}>
                          <CellValue
                            dataTestId={slugifyStrings(
                              instrument.instrumentNarrative,
                              owner.owner,
                              'closing-balance-cell-value',
                              forecast.forecastDate ?? ''
                            )}
                            {...amountRules}
                            value={
                              capitalStructure?.debtInstrumentsByYear[forecast.forecastDate ?? '']
                                ?.instruments[instrument.instrumentId].perOwner[owner.owner] ?? 0
                            }
                          />
                        </td>
                      ))}
                      <td />
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </>
        )}
      </tbody>
    </>
  ) : (
    <></>
  );
};

export default ShareholderDebtSection;
