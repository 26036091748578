import { WithValidationResult } from '@app/shared/interfaces/with-validation-result';
import { AppDispatch, RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import env from '@environment';
import { BenchmarkingInputsCalculationResultsDto } from '@app/shared/models/contracts/benchmarking-input-calculation-results-dto';
import api from '@server/api-config';

export interface BenchmarkingState {
  benchmarkingInputs: BenchmarkingInputsCalculationResultsDto;
}

export const calculateBenchmarkingInputs = createAsyncThunk<
  { benchmarkingInputs: BenchmarkingInputsCalculationResultsDto },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculateBenchmarkingInputs', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;
  const benchmarkingInputsResults = await api.post<
    WithValidationResult<BenchmarkingInputsCalculationResultsDto>
  >(`${env.apiUrl}/calculate/benchmarking-inputs`, JSON.stringify(project));
  return { benchmarkingInputs: benchmarkingInputsResults.data.result };
});

export const benchmarkingSlice = createSlice({
  name: 'benchmarking',
  initialState: {
    benchmarkingInputs: {},
  } as BenchmarkingState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(calculateBenchmarkingInputs.fulfilled, (state, action) => {
      state.benchmarkingInputs = {
        ...state.benchmarkingInputs,
        ...action.payload.benchmarkingInputs,
      };
    });
  },
});

export default benchmarkingSlice.reducer;
