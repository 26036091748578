import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import FormCheckbox, {
  FormCheckboxOption,
} from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import {
  maxLengthValidator,
  notAllSelectedValidator,
  getRequiredValidator,
  uniqueEventNarrative,
} from '@app/core/validations/hook-forms/validators';
import { useLocale } from '@app/core/hooks/useLocale';
import { findSelectedCases, mapBuildStructureErrorToMessage } from '../utils/EventBuilderHelpers';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { cloneDeep } from '@app/shared/helpers';
import { calculateBuildStructure } from '@app/core/store/capital-structure-slice';
import {
  CapitalStructureEventSeverity,
  EventKey,
} from '@app/shared/models/contracts/enums/shared-enums';
import { CapitalStructureEventFaults } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import '../event-form-modal-module.scss';
import store from '@app/core/store/store';
import * as notificationActions from '@app/core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';

interface UnlinkEventModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  eventId: string;
}

interface UnlinkEventFormDto {
  selectedCases: string[];
  narrative: string;
}

const PARENT_CLASSNAME = 'form';
export const UnlinkEventFormModal: FC<UnlinkEventModalProps> = ({
  isOpen,
  setIsOpen,
  eventId,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const maxEventNameLength = useAppSelector(
    (state) => state.uiValues.maxRefinancingEventNameLength
  );
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const existingEvents = Object.fromEntries(
    Object.entries(project.capitalStructures[capitalStructureKey].events).filter(
      ([eventId, _]) =>
        !project.capitalStructures[capitalStructureKey].eventSets[
          EventKey.OpmOnly
        ]?.events.includes(eventId)
    )
  );
  const [faults, setFaults] = useState<CapitalStructureEventFaults[]>([]);

  const options: FormCheckboxOption[] = project.pwermInput.cases
    .filter((cases) =>
      findSelectedCases(project, eventId, capitalStructureKey).includes(cases.caseId)
    )
    .map((cases) => ({
      viewValue: cases.narrative,
      value: cases.caseId,
    }));

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      selectedCases: [],
      narrative: '',
    },
  });

  useEffect(() => {
    formMethods.reset();
  }, [formMethods, isOpen]);

  useEffect(() => {
    setFaults([]);
  }, [isOpen]);

  const formSubmitHandler: SubmitHandler<UnlinkEventFormDto> = async (data) => {
    const { selectedCases, narrative } = data;

    let cases = cloneDeep(project.pwermInput.cases);
    const eventSets = cloneDeep(project.capitalStructures[capitalStructureKey].eventSets);
    const events = cloneDeep(project.capitalStructures[capitalStructureKey].events);

    const newEventId = uuidv4();
    const newEvent = cloneDeep(events[eventId]);
    newEvent.narrative = narrative;
    events[newEventId] = newEvent;

    selectedCases.forEach((caseId) => {
      const previousEventSetId = cases.find((cases) => cases.caseId === caseId)?.eventSetId;
      const newEventSetId = uuidv4();
      cases = cases.map((cases) => {
        return cases.caseId === caseId ? { ...cases, eventSetId: newEventSetId } : cases;
      });
      eventSets[newEventSetId] = {
        events: eventSets[previousEventSetId!].events.filter((e) => e !== eventId),
      };
      eventSets[newEventSetId].events.push(newEventId);
    });

    const result = await dispatch(
      calculateBuildStructure({
        project: {
          ...project,
          capitalStructures: {
            ...project.capitalStructures,
            [capitalStructureKey]: {
              ...project.capitalStructures[capitalStructureKey],
              eventSets: eventSets,
              events: events,
            },
          },
          pwermInput: {
            ...project.pwermInput,
            cases: cases,
          },
        },
      })
    ).unwrap();

    if (result.hasErrors) {
      const errors = result.buildStructure.buildStructures[capitalStructureKey].faults.filter(
        (f) => f.severity === CapitalStructureEventSeverity.Error
      );
      const errorsWithoutReferenceToEvents = errors.filter((e) => !e.event);
      setFaults(errorsWithoutReferenceToEvents);
    } else {
      setFaults([]);
      setIsOpen(false);
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: l('_EventAddedSuccessfully', { eventNarrative: narrative }),
          autoHide: true,
        })
      );
    }
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      title={l('_UnlinkEvent')}
      isOpen={isOpen}
      onCloseButtonClick={() => setIsOpen(false)}>
      <FormProvider {...formMethods}>
        <form
          data-testid="copy-event-form"
          onSubmit={formMethods.handleSubmit(formSubmitHandler)}
          onBlur={() => formMethods.trigger()}>
          <FormCheckbox
            name="selectedCases"
            label={l('_SelectCases')}
            rules={{
              ...getRequiredValidator(),
              ...notAllSelectedValidator(options),
            }}
            options={options}
          />
          <FormField
            name="narrative"
            label={l('_NewEventNarrative')}
            placeholder={l('_Narrative')}
            {...textFieldFormattingProps}
            rules={{
              ...getRequiredValidator(),
              ...uniqueEventNarrative(existingEvents),
              ...maxLengthValidator(maxEventNameLength),
            }}
          />
          {faults.length > 0 && (
            <div className={`${PARENT_CLASSNAME}__errors`}>
              {l('_CannotUnlinkEventDueToTheFollowingErrors')}
              <ul>
                {faults.map((f) => (
                  <li key={f.errorCode}>{mapBuildStructureErrorToMessage(f)}</li>
                ))}
              </ul>
            </div>
          )}
          <div className={`${PARENT_CLASSNAME}__actions`}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              data-testid="cancel-button"
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(false);
              }}>
              {l('_Cancel')}
            </Button>
            <Button
              type="submit"
              data-testid="submit-button"
              appearance={ButtonAppearance.DEFAULT_PRIMARY}>
              {l('_UnlinkEvent')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  );
};
