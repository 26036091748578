import React, { useEffect } from 'react';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { setHideUnrelatedEvents } from '@app/core/store/ui-values-slice';
import { useLocale } from '@app/core/hooks/useLocale';
import { Toggle, ToggleSize } from '@app/shared/components/toggle/Toggle';
import styles from '../../build-structure-tab/build-structure.module.scss';

const HideUnrelatedEvents: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();

  const hideUnrelatedEventsState = useAppSelector((state) => state.uiValues.hideUnrelatedEvents);

  type EventHeaderFormModel = {
    hideUnrelatedEventsState: boolean;
  };

  const formMethods = useForm<EventHeaderFormModel>({
    ...formConfigBase,
    defaultValues: { hideUnrelatedEventsState: hideUnrelatedEventsState },
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset({ hideUnrelatedEventsState: hideUnrelatedEventsState });
  }, [reset, hideUnrelatedEventsState]);

  return (
    <div className={styles['unrelated-events-container']}>
      <FormProvider {...formMethods}>
        <span>
          <Toggle
            className={styles['unrelated-events']}
            name="hideUnrelatedEvents"
            label={l('_HideUnrelatedEvents')}
            toggleSize={ToggleSize.Medium}
            onChange={(e) => dispatch(setHideUnrelatedEvents(e.target.checked))}
            data-testid="hide-unrelated-events-toggle"
            forceChecked={hideUnrelatedEventsState}
          />
        </span>
      </FormProvider>
    </div>
  );
};

export default HideUnrelatedEvents;
