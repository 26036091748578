import { FC, Fragment } from 'react';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useParams } from 'react-router-dom';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getNetDebtIndex } from '@core/store/project-slice-selectors';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { getRequiredValidator } from '@core/validations/hook-forms/validators';
import Button from '@app/shared/components/button/Button';
import PlusSvg from '@app/shared/icons/Plus';
import MinusRoundedSvg from '@app/shared/icons/MinusRounded';
import { v4 as uuidv4 } from 'uuid';
import {
  DebtItemDto,
  ForecastDto,
  NetDebtItemValueDto,
} from '@app/shared/models/contracts/project-dto';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  numericBaseFieldFormattingProps,
  textFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import {
  getDealThesisInputCaseForecastDate,
  getDealThesisInputCaseValuationDate,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { DealThesisSpacerRow2 } from '../DealThesisSpacerRow2';

interface NetDebtPartialTableProps {
  onRowsChange: () => void;
}

const NetDebtPartialTable2: FC<NetDebtPartialTableProps> = ({ onRowsChange }): JSX.Element => {
  const { l } = useLocale();
  const { control } = useFormContext();
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const numberOfResultsForecastYears = Object.keys(results.cases[caseId!].forecastDates).length;
  const caseResults = results.cases[caseId!];
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);
  const numberOfInputHistoricYears = project.pwermInput.historicForecasts.length;
  // results data sliced such that a decrease in historic years is immediately rendered without depending on results
  const historicForecasts = Object.entries(caseResults.historicDates)
    .map(([historicYears, value]) => ({
      historicYears,
      ...value,
    }))
    .slice(0, numberOfInputHistoricYears);
  const numberOfHistoricYears = historicForecasts.length;
  const columnCount =
    (caseData?.forecasts?.length ?? 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT +
    numberOfHistoricYears;
  const showHistoricDataColumns = useAppSelector(
    (state) => state.uiValues.userSelections.dealThesis.showHistoricDataColumns
  );

  const { replace: replaceNetDebtField } = useFieldArray({
    name: 'forecasts',
    control: control,
  });

  const { append: appendNetDebtField, remove: removeDebtField } = useFieldArray({
    name: 'netDebtItems',
    control: control,
  });

  const addNetDebt = () => {
    const netDebtItemId = uuidv4();
    appendNetDebtField({ netDebtItemId, historicalValue: null, narrative: '' } as DebtItemDto, {
      shouldFocus: false,
    });

    replaceNetDebtField([
      ...(caseData?.forecasts.map((forecast: ForecastDto) => {
        return {
          ...forecast,
          netDebtItems: [
            ...forecast.netDebtItems,
            { netDebtItemId, value: 0 } as NetDebtItemValueDto,
          ],
        };
      }) ?? []),
    ]);

    onRowsChange();
  };

  const removeNetDebtItem =
    (netDebtItemToDelete: DebtItemDto, netDebtIndex: number) => (): void => {
      removeDebtField(netDebtIndex);
      replaceNetDebtField([
        ...(caseData?.forecasts.map((forecast: ForecastDto) => {
          return {
            ...forecast,
            netDebtItems: [
              ...forecast.netDebtItems.filter(
                (netDebtItem) => netDebtItem.netDebtItemId !== netDebtItemToDelete.netDebtItemId
              ),
            ],
          };
        }) ?? []),
      ]);

      onRowsChange();
    };

  return (
    <>
      <tbody>
        {caseId && (
          <>
            <tr>
              <th className="table-primary__cell--header" colSpan={columnCount}>
                {l('_NetDebt')}
              </th>
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-end-separator">
                {l('_CashNegative')}
              </th>
              {showHistoricDataColumns &&
                historicForecasts.map((_, index) => (
                  <td key={index} className="table-primary__cell--section-end-separator" />
                ))}
              <td className="table-primary__cell--section-end-separator">
                <GridTextField
                  name="historicalCashValue"
                  {...numericBaseFieldFormattingProps}
                  placeholder="0"
                  alignment={Alignment.Right}
                />
              </td>
              {caseForecasts?.map((forecast, index) => (
                <td
                  key={forecast.forecastId + 'cash-value'}
                  className="table-primary__cell--section-end-separator">
                  <GridTextField
                    name={`forecasts[${index}].cashValue`}
                    {...numericBaseFieldFormattingProps}
                    placeholder="0"
                    rules={{
                      ...getRequiredValidator(),
                    }}
                    alignment={Alignment.Right}
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-end-separator" />
            </tr>
            {caseData?.netDebtItems.map((netDebtItem, netDebtItemIndex) => (
              <tr key={netDebtItem.netDebtItemId + 'debt-item'}>
                <th className="table-primary__cell--vertical-separator">
                  <GridTextField
                    name={`netDebtItems[${netDebtItemIndex}].narrative`}
                    rules={{ ...getRequiredValidator() }}
                    placeholder={l('_AddNarrative')}
                    {...textFieldFormattingProps}
                  />
                </th>
                {showHistoricDataColumns && historicForecasts.map((_, index) => <td key={index} />)}
                <td>
                  <GridTextField
                    name={`netDebtItems[${netDebtItemIndex}].historicalValue`}
                    {...numericBaseFieldFormattingProps}
                    placeholder="0"
                    alignment={Alignment.Right}
                  />
                </td>
                {caseForecasts?.map((forecast, index) => (
                  <Fragment key={forecast.forecastId + 'cash-value-totals'}>
                    <td>
                      <GridTextField
                        name={`forecasts[${index}].netDebtItems[${getNetDebtIndex(
                          forecast,
                          netDebtItem
                        )}].value`}
                        {...numericBaseFieldFormattingProps}
                        placeholder="0"
                        rules={{
                          ...getRequiredValidator(),
                        }}
                        alignment={Alignment.Right}
                      />
                    </td>
                  </Fragment>
                ))}
                <td className="table-primary__cell--actions">
                  <Button
                    appearance={ButtonAppearance.CLEAN}
                    size={ButtonSize.TABLE_ACTION}
                    title={l('_DeleteItem')}
                    onClick={removeNetDebtItem(netDebtItem, netDebtItemIndex)}>
                    <MinusRoundedSvg />
                  </Button>
                </td>
              </tr>
            ))}
            <tr>
              <td className="table-primary__cell--vertical-separator">
                <div className="hide-on-screenshot">
                  <Button
                    appearance={ButtonAppearance.DEFAULT_TERTIARY}
                    size={ButtonSize.TABLE_ACTION}
                    startIcon={<PlusSvg />}
                    onClick={() => addNetDebt()}>
                    {l('_AddItem')}
                  </Button>
                </div>
              </td>
              <td colSpan={columnCount - 1} />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                <strong>{l('_TotalNetDebt')}</strong>
              </th>
              {showHistoricDataColumns &&
                historicForecasts.map((_, index) => (
                  <td key={index} className="table-primary__cell--section-start-separator" />
                ))}
              <td className="table-primary__cell--section-start-separator">
                <CellValue
                  {...amountRules}
                  strong
                  value={getDealThesisInputCaseValuationDate(
                    results,
                    caseId!,
                    (caseItem) => caseItem.totalNetDebt
                  )}
                />
              </td>
              {caseForecasts?.map((forecast) => (
                <td
                  key={forecast.forecastId + 'total-debt'}
                  className="table-primary__cell--section-start-separator">
                  <CellValue
                    {...amountRules}
                    strong
                    value={getDealThesisInputCaseForecastDate(
                      results,
                      caseId!,
                      forecast.forecastYear,
                      (forecast) => forecast?.totalNetDebt
                    )}
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <DealThesisSpacerRow2 />
          </>
        )}
      </tbody>
    </>
  );
};

export default NetDebtPartialTable2;
