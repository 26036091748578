import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { FC, Fragment } from 'react';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import {
  positiveValueValidator,
  getRequiredValidator,
} from '@core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { selectSlnAndPrefSharesInstruments } from '@core/store/project-slice-selectors';
import { selectSlnAndPrefSharesForecasts } from '@app/core/store/pwerm-calculation-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { GridDatepickerField } from '@app/shared/components/grid-controls/grid-datepicker-field/GridDatepickerField';
import { OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { isInstrumentOwnerAmountEntered } from '@app/shared/helpers';
import {
  DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT,
  DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT,
} from '@app/shared/constants/table-config';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis/DealThesisPage';
import { DealThesisSpacerRow } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisSpacerRow';
import { getEquityInstrumentOwnerships } from '@app/shared/helpers/equity-instruments/get-equity-instrument-ownerships';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import { useLocale } from '@app/core/hooks/useLocale';

interface DealThesisCaseSlnAndPrefShareSectionProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseSlnAndPrefShareSection: FC<
  DealThesisCaseSlnAndPrefShareSectionProps
> = ({ handleDataSubmit }) => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);
  const slnAndPrefSharesForecasts = useAppSelector(selectSlnAndPrefSharesForecasts);
  const { l } = useLocale();

  const { control } = useFormContext<DealThesisFormModel>();
  const { fields } = useFieldArray({
    name: 'filteredSlnAndPrefSharesInstruments',
    control,
  });

  const sectionColCount =
    (caseData?.forecasts.length ?? 0) + DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEntered
  );

  return !filteredSlnAndPrefSharesInstruments.length ? (
    <></>
  ) : (
    <>
      <tbody>
        <tr className="table-primary__row--plain">
          <td colSpan={sectionColCount} className="table-primary__cell--header">
            Shareholder Debt Instruments
          </td>
        </tr>
        {fields.map((instrument, instrumentIndex) => (
          <Fragment key={instrument.id}>
            <tr className="table-primary__row--header">
              <th
                colSpan={sectionColCount}
                className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                {instrument.instrumentNarrative}
              </th>
            </tr>
            <tr className="table-primary__row--plain">
              <th className="table-primary__cell--vertical-separator">Coupon start date</th>
              <td>
                <GridDatepickerField
                  name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponStartDate`}
                  rules={{
                    ...getRequiredValidator(),
                  }}
                  formSubmitHandler={handleDataSubmit}
                />
              </td>
              <td
                colSpan={
                  (caseData?.forecasts.length ?? 0) + DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT
                }
              />
            </tr>
            <tr className="table-primary__row--plain">
              <th className="table-primary__cell--vertical-separator">Coupon %</th>
              <td>
                <GridTextField
                  name={`filteredSlnAndPrefSharesInstruments.${instrumentIndex}.couponValue`}
                  {...percentageFieldFormattingProps}
                  data-testid={slugifyStrings(
                    instrument.instrumentNarrative,
                    'dealthesis-coupon-val'
                  )}
                  rules={{
                    ...getRequiredValidator(),
                    ...positiveValueValidator(true),
                  }}
                  alignment={Alignment.Right}
                />
              </td>
              <td
                colSpan={
                  (caseData?.forecasts.length ?? 0) + DEAL_THESIS_TABLE_DATA_ADDITIONAL_COL_COUNT
                }
              />
            </tr>
            {getEquityInstrumentOwnerships(instrument, (x) => x.amount).map((owner, index) => (
              <Fragment key={index}>
                <tr className="table-primary__row--secondary">
                  <th
                    colSpan={
                      (caseData?.forecasts.length ?? 0) +
                      DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT
                    }
                    className="table-primary__cell--indentation table-primary__cell--strong">
                    {OwnerType[owner.owner]}
                  </th>
                </tr>
                <tr>
                  <th className="table-primary__cell--vertical-separator table-primary__cell--indentation">
                    {l('_ClosingBalance')}
                  </th>
                  <td>
                    <CellValue {...amountRules} value={owner.amount} />
                  </td>
                  {caseData?.forecasts.map((forecast, index) => (
                    <td key={index}>
                      <CellValue
                        {...amountRules}
                        value={
                          slnAndPrefSharesForecasts?.[caseData?.caseId]?.[
                            instrument.instrumentId
                          ]?.[forecast.forecastYear]?.perOwner[owner.owner]
                        }
                      />
                    </td>
                  ))}
                  <td />
                </tr>
              </Fragment>
            ))}
            <DealThesisSpacerRow />
          </Fragment>
        ))}
      </tbody>
    </>
  );
};
