import {
  EquityInstrumentDto,
  EquityRefinancingEventDto,
  MultipleDto,
} from '@app/shared/models/contracts/project-dto';
import { dateFormatter } from '@app/shared/formatters';
import { FormCheckboxOption } from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import { EquityItem } from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemFormErf';
import { l } from '@locale/setupI18n';

export const getRequiredValidator = () => {
  return {
    required: l('_FieldIsRequired'),
  };
};

export const minValueValidator = (charCount: number) => {
  return {
    min: {
      value: charCount,
      message: l('_MinimumValueIsCharacterCount', { charCount }),
    },
  };
};

export const uniqueNarrativePerEquityInstrumentType = (
  equityItems: EquityInstrumentDto[],
  activeEquityInstrument: EquityInstrumentDto
) => {
  return {
    validate: (value: string) => {
      const duplicateEntries = equityItems.filter(
        (equityItem) =>
          equityItem.instrumentNarrative.toLowerCase() === value.toLowerCase() &&
          equityItem.instrumentId !== activeEquityInstrument.instrumentId &&
          equityItem.type === activeEquityInstrument.type
      );

      return duplicateEntries.length === 0 ? true : l('_InstrumentNarrativeMustBeUniquePerType');
    },
  };
};

export const uniqueNarrativePerEquityInstrumentType2 = (
  equityItems: EquityItem[],
  activeEquityInstrument: EquityItem
) => {
  return {
    validate: (value: string) => {
      const duplicateEntries = equityItems.filter(
        (equityItem) =>
          equityItem.instrumentNarrative.toLowerCase() === value.toLowerCase() &&
          equityItem.instrumentId !== activeEquityInstrument.instrumentId &&
          equityItem.type === activeEquityInstrument.type
      );

      return duplicateEntries.length === 0 ? true : l('_InstrumentNarrativeMustBeUniquePerType');
    },
  };
};

export const uniqueEventNarrative = (events: Record<string, EquityRefinancingEventDto>) => {
  return {
    validate: (value: string) => {
      const duplicateEntries = Object.values(events).filter(
        (event) => event.narrative.toLowerCase() === value.toLowerCase()
      );

      return duplicateEntries.length === 0 ? true : l('_EventNarrativeMustBeUnique');
    },
  };
};

export const greaterThanIfValidator = <TItem, TIf extends boolean>(
  minimumValue: number,
  item: TItem,
  _if: (item: TItem) => TIf
) => {
  return {
    validate: (value: number) => {
      return _if(item) ? greaterThanValidator(minimumValue).validate(value) : true;
    },
  };
};

export const greaterThanOrEqualIfValidator = <TItem, TIf extends boolean>(
  minimumValue: number,
  item: TItem,
  _if: (item: TItem) => TIf
) => {
  return {
    validate: (value: number) => {
      return _if(item)
        ? value >= minimumValue
          ? true
          : l('_ValueMustBeGreaterThanOrEqualToValue', { minimumValue })
        : true;
    },
  };
};

export const greaterThanValidator = (minimumValue: number) => {
  return {
    validate: (value: number) =>
      value > minimumValue ? true : l('_ValueMustBeGreaterThanValue', { minimumValue }),
  };
};

export const greaterThanOrEqualToValidator = (minimumValue: number) => {
  const message = l('_GreaterThanOrEqualToValidator', { minimumValue });
  return {
    validate: (value: number) => (value >= minimumValue ? true : message),
  };
};

export const greaterThanOrNullValidator = (minimumValue: number) => {
  return {
    validate: (value: number | null) => {
      if (value === null) {
        return true;
      }

      return value > minimumValue ? true : l('_ValueMustBeGreaterThanValue', { minimumValue });
    },
  };
};

export const positiveValueValidator = (allowZeroValue: boolean) => {
  return {
    validate: (value: string) => {
      return allowZeroValue
        ? value === '' || value === null || Number(value) >= 0
          ? true
          : l('_ValueCannotBeNegative')
        : value === '' || value === null || Number(value) > 0
        ? true
        : l('_ValueMustBeAPositiveNumber');
    },
  };
};

export const positiveValueIfValidator = <TItem, TIf extends boolean>(
  allowZeroValue: boolean,
  item: TItem,
  _if: (item: TItem) => TIf
) => {
  return {
    validate: (value: string) => {
      return _if(item) ? positiveValueValidator(allowZeroValue).validate(value) : true;
    },
  };
};

export const invalidDateValidator = () => {
  return {
    validate: (value: string | null) => {
      if (value === null || value === undefined) {
        return true;
      }

      return value.toString() !== 'Invalid Date' ? true : l('_InvalidDate');
    },
  };
};

export const greaterDateThanValidator = (minimumValue: string | null, message?: string) => {
  return {
    validate: (value: string | null) => {
      if (value === null || minimumValue === null) {
        return true;
      }

      const errorMessage = message
        ? message
        : l('_DateMustBeAfter', {
            earliestDate: dateFormatter(minimumValue, { dateStyle: 'short' }),
          });

      return Date.parse(value) > Date.parse(minimumValue) ? true : errorMessage;
    },
  };
};

export const greaterOrEqualDateThanValidator = (minimumValue: string | null, message?: string) => {
  return {
    validate: (value: string | null) => {
      if (value === null || minimumValue === null) {
        return true;
      }

      const errorMessage = message
        ? message
        : l('_DateMustBeEqualOrAfter', {
            earliestDate: dateFormatter(minimumValue, { dateStyle: 'short' }),
          });

      return Date.parse(value) >= Date.parse(minimumValue) ? true : errorMessage;
    },
  };
};

export const lesserOrEqualDateThanValidator = (maximumValue: string | null, message?: string) => {
  return {
    validate: (value: string | null) => {
      if (value === null || maximumValue === null) {
        return true;
      }

      const errorMessage = message
        ? message
        : l('_DateMustBeEqualOrBefore', {
            latestDate: dateFormatter(maximumValue, { dateStyle: 'short' }),
          });

      return Date.parse(value) <= Date.parse(maximumValue) ? true : errorMessage;
    },
  };
};

export const maxLengthValidator = (charCount: number) => {
  return {
    maxLength: {
      value: charCount,
      message: l('_MaxCharacterCountIsLimit', { limit: charCount }),
    },
  };
};

export const minMaxValidator = (minValue: number, maxValue: number) => {
  return {
    validate: (value: number) =>
      (value >= minValue && value <= maxValue) || !value
        ? true
        : l('_FieldValueMustBeBetweenMinValueAndMaxValue', { minValue, maxValue }),
  };
};

export const percentageValidator = {
  ...minMaxValidator(0, 100),
};

export const greaterOrEqualLessThanValidator = (minValue: number, maxValue: number) => {
  return {
    validate: (value: number) =>
      (value >= minValue && value < maxValue) || !value
        ? true
        : l('_ValueMustBeGreaterOrEqualToMinValueAndLessThanMaxValue', { minValue, maxValue }),
  };
};

export const collaringPercentageValidator = {
  ...greaterOrEqualLessThanValidator(0, 50),
};

export const uniqueMultipleValidator = (multiples: MultipleDto[], currentItem: MultipleDto) => {
  return {
    validate: (value: string) => {
      const duplicateEntries = multiples.filter(
        (multipleItem) =>
          Number(multipleItem.multiple) === Number(value) &&
          multipleItem.multipleId !== currentItem.multipleId
      );

      return duplicateEntries.length === 0 ? true : l('_ExitMultipleMustBeUniquePerCase');
    },
  };
};

export const uniquenessValidator = (
  searchBetween: any[],
  propertyValue: string,
  validationMessage: string
) => {
  return {
    validate: (value: string) => {
      const duplicateEntries = searchBetween.filter((entry) => {
        return entry && propertyValue in entry ? entry[propertyValue] === value : false;
      });

      return duplicateEntries.length === 0 ? true : validationMessage;
    },
  };
};

export const entryMustExist = (
  searchBetween: any[],
  propertyValue: string,
  validationMessage: string
) => {
  return {
    validate: (value: string) => {
      const entryExists = searchBetween.filter(
        (entry) => entry && propertyValue in entry && entry[propertyValue] === value
      );

      return entryExists.length === 0 ? validationMessage : true;
    },
  };
};

export const notAllSelectedValidator = (options: FormCheckboxOption[]) => {
  return {
    validate: (selectedValues: string[]) => {
      return selectedValues.length !== options.length ? true : l('_EventCannotSelectAll');
    },
  };
};
