import { AxiosInstance, AxiosResponse } from 'axios';
import store from '@core/store/store';
import * as notificationActions from '@core/store/notification-slice';
import { projectSlice } from '@core/store/project-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import env from '@environment';
import { StatusCodes } from '@app/shared/enums/status-codes';
import { RootAppRoutes } from '@app/shared/enums/root-app-routes';
import {
  CodedDomainException,
  ErrorMessage,
  FluentValidationErrorData,
  ModelValidationError,
  ErrorWrappedWithValidationResult,
  ResponseWrappedWithValidationResult,
  FluentValidationErrorSeverity,
} from '@app/shared/interfaces/error-message';
import { globalNavigate } from '@app/shared/components/global-navigate/GlobalNavigate';
import i18n from 'i18next';
import { ValidationJobType } from '@app/shared/models/contracts/enums/shared-enums';
import { l } from 'src/locale/setupI18n';
import { ErrorCodes } from './code-enums';

const getDefaultErrorMessage = (error: ErrorMessage | CodedDomainException) =>
  error.response?.data?.message ?? error.message ?? 'Something went wrong.';

const getErrorMessage = (error: ErrorMessage | ErrorWrappedWithValidationResult) => {
  if (isErrorWrappedWithValidationResult(error)) {
    return filterOutValidationInfoMessages(error.response.data.validation.errors)[0].errorMessage;
  }

  if (isCodedDomainException(error)) {
    return handleCodedDomainException(error);
  }

  if (isModelValidationError(error)) {
    return (
      error.response?.data?.data?.['custom']?.[0] ?? getDefaultErrorMessage(error) // backend returns custom validations under the 'custom' key
    );
  }

  return getDefaultErrorMessage(error);
};

function isModelValidationError(
  error: ErrorMessage | ModelValidationError
): error is ModelValidationError {
  return error.response.data.type === 'PwcDeals.Api.Responses.InvalidModelState';
}

function isErrorWrappedWithValidationResult(
  error: ErrorMessage | ErrorWrappedWithValidationResult
): error is ErrorWrappedWithValidationResult {
  const wrappedError: ErrorWrappedWithValidationResult = error as ErrorWrappedWithValidationResult;
  return Array.isArray(wrappedError.response?.data?.validation?.errors);
}

function isResponseWrappedWithValidationResult(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: AxiosResponse<any, any> | ResponseWrappedWithValidationResult
): response is ResponseWrappedWithValidationResult {
  const wrappedResponse: ResponseWrappedWithValidationResult =
    response as ResponseWrappedWithValidationResult;
  return Array.isArray(wrappedResponse.data?.validation?.errors);
}

const isCodedDomainException = (
  error: ErrorMessage | CodedDomainException
): error is CodedDomainException => {
  return error.response.data.type === 'PwcDeals.Core.Exceptions.CodedDomainException';
};

const handleCodedDomainException = (error: CodedDomainException) => {
  const errorData = error.response.data;
  switch (errorData.errorCode) {
    case ErrorCodes.CALC_COLLARING_CONFIG_COLLARS_ALL_ITERATIONS:
      return l('_TheCapValueYouProvidedResultsInAllPointsBeingRemovedByCollaring');
    case ErrorCodes.CALC_PARTICIPATION_NO_COMBO_OF_HURDLES_IN_ORDER:
      return l('_ParticipationGrossUpsOutOfOrderError');
    case ErrorCodes.CSB_EVENT_LEAVES_NO_ORDINARY_IN_STRUCTURE:
      return l('_EventLeavesNoOrdinaryInStructure');
    case ErrorCodes.CSB_EVENT_LEAVES_NO_INSTRUMENTS_IN_STRUCTURE:
      return l('_EventLeavesNoInstrumentsInStructure');
    case ErrorCodes.CALC_HURDLE_BOUNDS_NOT_OPEN_ENDED:
      return l('_TrancheBoundsNotOpenEndedPleaseAddOpenTranche');
    case ErrorCodes.CALC_HURDLE_BOUNDS_OVERLAP:
    case ErrorCodes.CALC_HURDLE_BOUNDS_NOT_CONTIGUOUS:
    case ErrorCodes.CALC_HURDLE_CONDITIONS_IMPOSSIBLE:
      // these errors have server-rendered messages which include additional context about the issue.
      return errorData.message;
    default:
      return i18n.t(`_CodedDomainErrorCodeAndTitle`, {
        ErrorCode: errorData.errorCode,
        ErrorTitle: errorData.title,
      });
  }
};

const filterOutValidationInfoMessages = (errors: FluentValidationErrorData[]) =>
  errors.filter((e) => e.severity !== FluentValidationErrorSeverity.Info);

const dispatchValidationErrors = (
  jobType: ValidationJobType,
  errors: [FluentValidationErrorData]
) => {
  const generateErrorList = (
    errors: FluentValidationErrorData[],
    minimumSeverity: FluentValidationErrorSeverity
  ) => {
    const severityGreaterThanOrEqualTo = (
      error: FluentValidationErrorData,
      severity: FluentValidationErrorSeverity
    ) => {
      switch (severity) {
        case FluentValidationErrorSeverity.Error:
          return error.severity === FluentValidationErrorSeverity.Error;
        case FluentValidationErrorSeverity.Warning:
          return (
            error.severity === FluentValidationErrorSeverity.Error ||
            error.severity === FluentValidationErrorSeverity.Warning
          );
        case FluentValidationErrorSeverity.Info:
          return (
            error.severity === FluentValidationErrorSeverity.Error ||
            error.severity === FluentValidationErrorSeverity.Warning ||
            error.severity === FluentValidationErrorSeverity.Info
          );
        default:
          return false;
      }
    };

    const errorList = errors
      .filter((e) => severityGreaterThanOrEqualTo(e, minimumSeverity))
      .map((e) => e.errorMessage);
    const uniqueErrorList = [...new Set(errorList.map((errorMessage) => errorMessage))];
    return uniqueErrorList;
  };
  let errorList: string[] = [];
  switch (jobType) {
    case ValidationJobType.OpmCalculation:
      errorList = generateErrorList(errors, FluentValidationErrorSeverity.Warning);
      store.dispatch(projectSlice.actions.updateIsOpmValid(false));
      store.dispatch(notificationActions.setOpmErrorMessageList(errorList));
      break;
    case ValidationJobType.PwermCalculation:
      errorList = generateErrorList(errors, FluentValidationErrorSeverity.Warning);
      store.dispatch(projectSlice.actions.updateIsPwermValid(false));
      store.dispatch(notificationActions.setPwermErrorMessageList(errorList));
      break;
    case ValidationJobType.PwermInputsCalculation:
      errorList = generateErrorList(errors, FluentValidationErrorSeverity.Error);
      store.dispatch(projectSlice.actions.updateIsPwermInputsValid(false));
      store.dispatch(notificationActions.setPwermInputsErrorMessageList(errorList));
      break;
    case ValidationJobType.BenchmarkingInputsCalculation:
      errorList = generateErrorList(errors, FluentValidationErrorSeverity.Error);
      store.dispatch(projectSlice.actions.updateIsBenchmarkingInputsValid(false));
      store.dispatch(notificationActions.setBenchmarkingInputsErrorMessageList(errorList));
      break;
    case ValidationJobType.CapitalStructureCalculation:
      errorList = generateErrorList(errors, FluentValidationErrorSeverity.Error);
      store.dispatch(projectSlice.actions.updateIsCapitalStructureValid(false));
      store.dispatch(notificationActions.setCapitalStructureErrorMessageList(errorList));
      break;
    case ValidationJobType.CapitalStructureCalculationErf:
      errorList = generateErrorList(errors, FluentValidationErrorSeverity.Error);
      store.dispatch(projectSlice.actions.updateIsCapitalStructureErfValid(false));
      store.dispatch(notificationActions.setCapitalStructureErfErrorMessageList(errorList));
      break;
    default:
      break;
  }
};

const registerApiInterceptor = (api: AxiosInstance) => {
  api.interceptors.request.use(
    (request) => {
      return request;
    },
    (error: ErrorMessage) => {
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: getErrorMessage(error),
          errorInstanceId: error.response.data.instance,
          autoHide: true,
        })
      );
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      if (isResponseWrappedWithValidationResult(response) && !response.data.validation.isValid) {
        store.dispatch(
          notificationActions.showNotificationSnackbar({
            severity: SeverityType.warning,
            message: response.data.validation.errors[0].errorMessage,
            autoHide: true,
            errorCode: response.status,
            errorInstanceId: response.data.instance,
          })
        );
      }

      if (isResponseWrappedWithValidationResult(response)) {
        const jobType = (response as ResponseWrappedWithValidationResult).data.validationJobType;
        switch (jobType) {
          case ValidationJobType.OpmCalculation:
            store.dispatch(projectSlice.actions.updateIsOpmValid(true));
            store.dispatch(notificationActions.setOpmErrorMessageList(undefined));
            break;
          case ValidationJobType.PwermCalculation:
            store.dispatch(projectSlice.actions.updateIsPwermValid(true));
            store.dispatch(notificationActions.setPwermErrorMessageList(undefined));
            break;
          case ValidationJobType.PwermInputsCalculation:
            store.dispatch(projectSlice.actions.updateIsPwermInputsValid(true));
            store.dispatch(notificationActions.setPwermInputsErrorMessageList(undefined));
            break;
          case ValidationJobType.BenchmarkingInputsCalculation:
            store.dispatch(projectSlice.actions.updateIsBenchmarkingInputsValid(true));
            store.dispatch(notificationActions.setBenchmarkingInputsErrorMessageList(undefined));
            break;
          case ValidationJobType.CapitalStructureCalculation:
            store.dispatch(projectSlice.actions.updateIsCapitalStructureValid(true));
            store.dispatch(notificationActions.setCapitalStructureErrorMessageList(undefined));
            break;
          case ValidationJobType.CapitalStructureCalculationErf:
            store.dispatch(projectSlice.actions.updateIsCapitalStructureErfValid(true));
            store.dispatch(notificationActions.setCapitalStructureErfErrorMessageList(undefined));
            break;
          default:
            break;
        }
      }

      return response;
    },
    async (error) => {
      // convert from Blob response type if applicable
      if (error.request?.responseType === 'blob' && error.response?.data instanceof Blob) {
        const errorData = JSON.parse(await error.response.data.text());
        error.response.data = errorData;
      }

      setTimeout(() => {
        // timeout is dedicated to delay handleErrorCases and ensure, that navigate will be available outside of the react app, do not remove it.
        // in this case, calls which are triggered before the first render, will be handled properly
        handleErrorCases(error);
      }, 0);

      if (isErrorWrappedWithValidationResult(error)) {
        dispatchValidationErrors(
          error.response.data.validationJobType,
          error.response.data.validation.errors
        );
      } else if (isModelValidationError(error)) {
        store.dispatch(notificationActions.setOpmErrorMessageList(undefined));
        store.dispatch(projectSlice.actions.updateIsOpmValid(false));
      }

      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: getErrorMessage(error),
          autoHide: true,
          errorCode: error.response.status,
          errorInstanceId: error.response.data.instance,
        })
      );
      return Promise.reject({
        ...error,
        message: getErrorMessage(error),
      });
    }
  );
};

const handleErrorCases = (error: any) => {
  const isProjectDraftLoaded = store.getState().project.projectDraft.id;
  if (error.response.status === StatusCodes.Unauthorized) {
    document.location.href = `${env.apiUrl}/account/login?redirectUri=${document.URL}`;
  } else if (error.response.status === StatusCodes.Forbidden) {
    isProjectDraftLoaded
      ? store.dispatch(notificationActions.showNotificationModal())
      : (store.dispatch(notificationActions.closeNotification()),
        globalNavigate(RootAppRoutes.Forbidden));
  } else if (error.response.status === StatusCodes.NotFound) {
    isProjectDraftLoaded
      ? store.dispatch(notificationActions.showNotificationModal())
      : (store.dispatch(notificationActions.closeNotification()),
        globalNavigate(RootAppRoutes.NotFound));
  } else if (error.response.status === StatusCodes.InternalServerError) {
    isProjectDraftLoaded
      ? store.dispatch(notificationActions.showNotificationModal())
      : (store.dispatch(notificationActions.closeNotification()),
        globalNavigate(RootAppRoutes.Error));
  }
};

export default registerApiInterceptor;
