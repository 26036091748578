import { useLocale } from '@app/core/hooks/useLocale';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { RouteConstants } from '../../RouteConstants';

// These routes are used for navigating between the sub-pages of the Capital Structure page.
export const CapitalStructureRoutes = {
  BuildStructure: `../${RouteConstants.BuildStructure}`,
  PayoutLogic: `../${RouteConstants.PayoutLogic}`,
  CapTable: `../${RouteConstants.CapTable}`,
  ParticipationTable: `../${RouteConstants.ParticipationTable}`,
  SourcesAndUses: `../${RouteConstants.SourcesAndUses}`,
  CapTable2: `../${RouteConstants.CapTable2}`,
  ParticipationTable2: `../${RouteConstants.ParticipationTable2}`,
  BuildStructureErf: `../${RouteConstants.BuildStructureErf}`,
  PayoutLogicErf: `../${RouteConstants.PayoutLogicErf}`,
  CapTableErf: `../${RouteConstants.CapTableErf}`,
  ParticipationTableErf: `../${RouteConstants.ParticipationTableErf}`,
};

export const CapitalStructureNavigation = () => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isPwerm2Enabled = isDevFeatureEnabled(DevFeature.PWERM2);
  const isERFEnabled = isDevFeatureEnabled(DevFeature.ERF);
  const { l } = useLocale();

  const navigationItems: NavigationItem[] = [
    {
      label: l('_BuildStructure'),
      route: CapitalStructureRoutes.BuildStructure,
    },
    {
      label: l('_PayoutLogic'),
      route: CapitalStructureRoutes.PayoutLogic,
    },
    {
      label: l('_CapTable'),
      route: CapitalStructureRoutes.CapTable,
    },
    {
      label: l('_ParticipationTable'),
      route: CapitalStructureRoutes.ParticipationTable,
    },
    {
      label: l('_SourcesAndUses'),
      route: CapitalStructureRoutes.SourcesAndUses,
    },
  ];

  if (isPwerm2Enabled) {
    navigationItems.splice(3, 0, {
      label: `${l('_CapTable')} v2`,
      route: CapitalStructureRoutes.CapTable2,
    });
    navigationItems.splice(5, 0, {
      label: `${l('_ParticipationTable')} v2`,
      route: CapitalStructureRoutes.ParticipationTable2,
    });
  }

  if (isERFEnabled) {
    navigationItems.splice(1, 0, {
      label: `${l('_BuildStructure')} ERF`,
      route: CapitalStructureRoutes.BuildStructureErf,
    });
    navigationItems.splice(3, 0, {
      label: `${l('_PayoutLogic')} ERF`,
      route: CapitalStructureRoutes.PayoutLogicErf,
    });
    navigationItems.splice(6, 0, {
      label: `${l('_CapTable')} ERF`,
      route: CapitalStructureRoutes.CapTableErf,
    });
    navigationItems.splice(9, 0, {
      label: `${l('_ParticipationTable')} ERF`,
      route: CapitalStructureRoutes.ParticipationTableErf,
    });
  }

  return <SecondaryNavigation navigationItems={navigationItems} />;
};
