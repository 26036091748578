import { OpmCalculationResultsDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import * as OpmCalculationActions from '@app/core/store/opm-calculation-slice';
import * as ExportActions from '@app/core/store/export-slice';
import * as UiActions from '@app/core/store/ui-values-slice';
import * as NotificationActions from '@app/core/store/notification-slice';
import * as ProjectActions from '@app/core/store/project-slice';
import env from '@environment';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { OpmCalculationType } from '../store/opm-calculation-slice';
import { SignalrMessageTypes } from './SignalrMessageTypes';
import store from '../store/store';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { StatusCodes } from '@app/shared/enums/status-codes';
import { LOADER_HIDE_TIMEOUT, LOADER_TIMEOUT } from '@app/shared/constants/duration';
import { OperationProgressUpdateDto } from '@app/shared/models/contracts/operation-progress-update-dto';
import { WithValidationResult } from '@app/shared/interfaces/with-validation-result';
import { ValidationJobType } from '@app/shared/models/contracts/enums/shared-enums';
import { t } from 'i18next';
import { translationKeys } from '@locale/setupI18n';
import { SystemSettingsDto } from '@app/shared/models/contracts/application-configuration-dto';

const RECONNECT_PERIOD = 1000;

let connection: HubConnection;

const start = async () => {
  try {
    connection = new HubConnectionBuilder()
      .withUrl(`${env.apiUrl}/hubs/signalr`)
      .configureLogging('Information')
      .build();
    await connection.start().then(() => setupMessagesBehaviour());
  } catch (err) {
    setTimeout(() => start().then(() => setupMessagesBehaviour()), RECONNECT_PERIOD);
  }
};

const setupMessagesBehaviour = () => {
  connection.onclose(async () => {
    await start();
  });

  connection.on(
    SignalrMessageTypes.OpmCalculationComplete,
    async (results: OpmCalculationResultsDto, type: OpmCalculationType) => {
      if (type === OpmCalculationType.RealWorld) {
        await new Promise((resolve) => setTimeout(resolve, LOADER_HIDE_TIMEOUT));
        store.dispatch(OpmCalculationActions.processOpmRealWorldResults(results));
      } else {
        store.dispatch(OpmCalculationActions.processOpmRiskFreeResults(results));
      }
    }
  );

  connection.on(SignalrMessageTypes.OpmExportComplete, (jobId: number) => {
    store.dispatch(ExportActions.downloadExport(jobId));
  });

  connection.on(SignalrMessageTypes.ClientScheduleExportComplete, (jobId: number) => {
    store.dispatch(ExportActions.downloadExport(jobId));
  });

  connection.on(SignalrMessageTypes.SaveComplete, () => {
    store.dispatch(UiActions.setDisplayOperationProgress(false));
    store.dispatch(ProjectActions.saveComplete());
    store.dispatch(UiActions.setIsSaving(false));
  });

  connection.on(SignalrMessageTypes.OpmCalculationRunOnSave, () => {
    store.dispatch(UiActions.setDisplayOperationProgress(true));
    store.dispatch(UiActions.setOpmCalcRunningStatus(true));
  });

  connection.on(SignalrMessageTypes.SystemSettingsUpdate, (settings: SystemSettingsDto) => {
    store.dispatch(UiActions.setSystemSettings(settings));
  });

  connection.on(
    SignalrMessageTypes.OperationProgressUpdate,
    (update: OperationProgressUpdateDto) => {
      store.dispatch(UiActions.setOperationProgress(update));
    }
  );

  connection.on(SignalrMessageTypes.OperationError, (errorCode: number, errorMessage: string) => {
    store.dispatch(
      NotificationActions.showNotificationSnackbar({
        severity: SeverityType.error,
        message: errorMessage,
        autoHide: true,
        errorCode: StatusCodes.BadRequest,
        errorInstanceId: undefined,
      })
    );
    store.dispatch(UiActions.setDisplayOperationProgress(false));
    store.dispatch(UiActions.setIsSaving(false));
  });

  connection.on(
    SignalrMessageTypes.ValidationError,
    (validationResult: WithValidationResult<null>) => {
      if (!validationResult.validation.isValid && validationResult.validation.errors.length > 0) {
        const jobType = validationResult.jobType;
        const errors = validationResult.validation.errors;
        const message = errors[0].errorMessage;
        const translationKey = getValidationMessageKey(jobType);

        // turn off escaping for the i18next interpolation so it doesn't
        // mangle quote marks etc. This is OK to do as we trust the source
        // of the error messages (fluentvalidation in the backend API)
        store.dispatch(
          NotificationActions.showNotificationSnackbar({
            severity: SeverityType.error,
            message: t(translationKey, { message: message, interpolation: { escapeValue: false } }),
            errorInstanceId: undefined,
            autoHide: true,
          })
        );
      }

      store.dispatch(UiActions.setIsSaving(false));
    }
  );
};
start();

const getValidationMessageKey = (jobType: ValidationJobType): translationKeys => {
  switch (jobType) {
    case ValidationJobType.OpmCalculation:
      return '_OpmCalculationFailedWithMessage';
    case ValidationJobType.OpmExport:
      return '_OpmExportFailedWithMessage';
    case ValidationJobType.Save:
      return '_ProjectSaveFailedWithMessage';
    case ValidationJobType.ClientScheduleExport:
      return '_ClientScheduleExportFailedWithMessage';
    default:
      throw new Error('Unknown Validation Job Type');
  }
};

// Enabling browser reconnect to websocket when tab is idle
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    if (connection?.state !== HubConnectionState.Connected) {
      start();
    }
  }
});
export const runOpmCalculation = async (
  projectId: number,
  userId: number,
  project: ProjectDto,
  type: OpmCalculationType
) => {
  store.dispatch(UiActions.setDisplayOperationProgress(true));
  store.dispatch(UiActions.setOpmCalcRunningStatus(true));
  await new Promise((resolve) => setTimeout(resolve, LOADER_TIMEOUT));
  connection.send(SignalrMessageTypes.RunOpmCalculation, projectId, userId, project, type);
};

export const runOpmExport = async (
  projectId: number,
  userId: number,
  project: ProjectDto,
  type: OpmCalculationType
) => {
  store.dispatch(UiActions.setDisplayOperationProgress(true));
  store.dispatch(UiActions.setExportRunningStatus(true));
  await new Promise((resolve) => setTimeout(resolve, LOADER_TIMEOUT));
  connection.send(SignalrMessageTypes.RunOpmExport, projectId, userId, project, type);
};

export const saveProject = async (projectId: number, userId: number, project: ProjectDto) => {
  connection.send(SignalrMessageTypes.SaveProject, projectId, userId, project);
};

export const runClientScheduleExport = async (
  projectId: number,
  userId: number,
  project: ProjectDto
) => {
  store.dispatch(UiActions.setDisplayOperationProgress(true));
  store.dispatch(UiActions.setExportRunningStatus(true));
  await new Promise((resolve) => setTimeout(resolve, LOADER_TIMEOUT));
  connection.send(SignalrMessageTypes.RunClientScheduleExport, projectId, userId, project);
};
