import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  selectPrefShareInstruments,
  selectSlnAndPrefSharesInstruments,
  selectSlnInstruments,
} from '@app/core/store/project-slice-selectors';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { EmptyValues } from '@app/shared/constants/empty-values';
import {
  enumKeyByValue,
  instrumentsSortFn,
  isInstrumentOwnerAmountEntered,
} from '@app/shared/helpers';
import {
  InstrumentType,
  OwnerType,
  WaterfallSections,
} from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { Pwerm2WaterfallPartialTableProps } from '../pwerm2-waterfall-shared';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import TableSectionValuesPerMultiple from '../../components/TableSectionValuesPerMultiple';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getWaterfallCaseYearMultipleInstrumentValue,
  getWaterfallCaseYearMultipleValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { Pwerm2CalculationWaterfallDto } from '@app/shared/models/contracts/pwerm2-calculation-results-dto';

export const Pwerm2WaterfallShareholderDebtDistributionsPartialTable: FC<
  Pwerm2WaterfallPartialTableProps
> = ({ columnsCount }): JSX.Element => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();

  const slnInstruments = useAppSelector(selectSlnInstruments);
  const prefShareInstruments = useAppSelector(selectPrefShareInstruments);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEntered
  );

  const renderDebtDistributionInstrumentSection = ({
    instrumentNarrative,
    instrumentId,
    couponValue,
  }: EquityInstrumentDto) => (
    <TableSectionValuesPerForecast
      title={`${instrumentNarrative} [${
        couponValue || couponValue === 0
          ? numberValueFormatter({
              value: couponValue,
              ...percentageValueFormatterProps,
            })
          : EmptyValues.EnDash
      }]`}
      key={instrumentId}
      renderSpacer={false}
      renderCell={({ forecast, caseItem }) => {
        return (
          <CellValue
            {...amountRules}
            value={getWaterfallCaseYearValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              (year) => year.shareholderDebtByInstrument[instrumentId]
            )}
          />
        );
      }}
      additionalTitleClassNames="table-primary__cell--indentation"
    />
  );

  const renderShareholderDebtInstrumentSection = ({
    instrumentNarrative,
    instrumentId,
  }: EquityInstrumentDto) => (
    <TableSectionValuesPerMultiple
      key={instrumentId}
      title={instrumentNarrative}
      renderCell={({ caseItem, forecast, multipleId }) => (
        <CellValue
          {...amountRules}
          value={getWaterfallCaseYearMultipleInstrumentValue(
            results,
            caseItem.caseId,
            forecast.forecastId,
            multipleId!,
            instrumentId,
            (instrument) => instrument.totalDistributions
          )}
        />
      )}
    />
  );

  const renderShareholderDebtDistributionsByOwnerType = (item: {
    rowTitle: string;
    ownerType: keyof typeof OwnerType;
  }) => {
    return (
      <>
        <TableSectionValuesPerMultiple
          key={item.ownerType}
          title={item.rowTitle}
          renderCell={({ caseItem, forecast, multipleId }) => {
            const total = filteredSlnAndPrefSharesInstruments.length
              ? getWaterfallCaseYearMultipleValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  multipleId!,
                  (multiple) => multiple.shareholderDebtDistributionsByOwner[item.ownerType] ?? 0
                )
              : undefined;

            return <CellValue {...amountRules} value={total} />;
          }}
        />
      </>
    );
  };

  const ownerLabels: Record<keyof typeof OwnerType, string> = {
    ['Institution']: l('_Sponsor'),
    ['Management']: l('_Management'),
    ['CoInvestor']: l('_CoInvestor'),
    ['Other']: l('_Other'),
  };

  const getSddOwnersPresent = (
    waterfall: Pwerm2CalculationWaterfallDto
  ): (keyof typeof OwnerType)[] => {
    const ownerSort: Record<keyof typeof OwnerType, number> = {
      ['Institution']: 0,
      ['Management']: 1,
      ['CoInvestor']: 2,
      ['Other']: 3,
    };

    const cases = Object.entries(waterfall.cases);
    const years = cases.flatMap(([_, caseItem]) => Object.entries(caseItem.years));
    const allOwners = years.flatMap(([_, year]) => Object.keys(year.shareholderDebtByOwnerType));
    const uniqueOwners = [...new Set(allOwners as (keyof typeof OwnerType)[])];
    uniqueOwners.sort((a, b) => ownerSort[a] - ownerSort[b]);
    return uniqueOwners;
  };

  const presentOwners = getSddOwnersPresent(results.waterfall);

  const sddOwnerSectionsConfig = presentOwners.map((ownerType: keyof typeof OwnerType) => {
    return {
      rowTitle: ownerLabels[ownerType],
      ownerType: ownerType,
    };
  });

  const totalShareholderDebtDistributionsByOwnerType = sddOwnerSectionsConfig.map(
    renderShareholderDebtDistributionsByOwnerType
  );

  const [isShareholderDebtDistributionsOpen, setIsShareholderDebtDistributionsOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_ShareholderDebtDistributionsTitle')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.ShareholderDebtDistributions)}
          isOpen={isShareholderDebtDistributionsOpen}
          setIsOpen={setIsShareholderDebtDistributionsOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isShareholderDebtDistributionsOpen,
        })}>
        {(slnInstruments.length > 0 || prefShareInstruments.length > 0) && (
          <TableSectionValuesPerForecast
            title={l('_ContractualCarryingValues')}
            renderSpacer={false}
            renderCell={() => <></>}
          />
        )}
        {slnAndPrefSharesInstruments
          .sort(instrumentsSortFn)
          .map((instrument) => renderDebtDistributionInstrumentSection(instrument))}
        <TableSectionValuesPerForecast
          title={l('_TotalContractualShareholderDebt')}
          renderCell={({ forecast, caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                value={
                  slnAndPrefSharesInstruments.length > 0
                    ? getWaterfallCaseYearValue(
                        results,
                        caseItem.caseId,
                        forecast.forecastId,
                        (year) => year.totalShareholderDebt
                      )
                    : undefined
                }
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--header-tertiary">{l('_SplitOfContractualDebt')}</th>
          <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
        </tr>
        {slnInstruments.length > 0 && (
          <TableSectionValuesPerForecast
            renderSpacer={false}
            title={l('_ShareholderLoanNotes')}
            renderCell={({ forecast, caseItem }) => {
              return (
                <CellValue
                  {...amountRules}
                  value={getWaterfallCaseYearValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    (year) =>
                      year.shareholderDebtByInstrumentType[
                        enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
                      ]
                  )}
                />
              );
            }}
          />
        )}
        {prefShareInstruments.length > 0 && (
          <TableSectionValuesPerForecast
            title={l('_PreferenceShares')}
            renderSpacer={false}
            renderCell={({ forecast, caseItem }) => {
              return (
                <CellValue
                  {...amountRules}
                  value={getWaterfallCaseYearValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    (year) =>
                      year.shareholderDebtByInstrumentType[
                        enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
                      ]
                  )}
                />
              );
            }}
          />
        )}

        {sddOwnerSectionsConfig.map((owner, index) => {
          const spacer = index === sddOwnerSectionsConfig.length - 1;

          return (
            <TableSectionValuesPerForecast
              key={owner.ownerType}
              title={owner.rowTitle}
              renderSpacer={spacer}
              renderCell={({ forecast, caseItem }) => {
                const value = getWaterfallCaseYearValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (year) => year.shareholderDebtByOwnerType[owner.ownerType]
                );

                return <CellValue {...amountRules} value={value} />;
              }}
            />
          );
        })}

        {Boolean(filteredSlnAndPrefSharesInstruments.length) && (
          <>
            <tr>
              <th className="table-primary__cell--header-tertiary">
                {l('_ShareholderDebtDistributionsTitle')}
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
            </tr>
            {filteredSlnAndPrefSharesInstruments
              .sort(instrumentsSortFn)
              .map((instrument) => renderShareholderDebtInstrumentSection(instrument))}
            <tr>
              <th className="table-primary__cell--header-tertiary">
                {l('_TotalShareholderDebtDistributionsTitle')}
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
            </tr>
            <TableSectionValuesPerMultiple
              title={l('_TotalDistributions')}
              renderCell={({ caseItem, forecast, multipleId }) => {
                const total = getWaterfallCaseYearMultipleValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  multipleId!,
                  (multiple) => multiple.totalShareholderDebtDistributions
                );

                return <CellValue {...amountRules} value={total} />;
              }}
            />
            <TableSectionValuesPerMultiple
              title={l('_Shortfall')}
              renderCell={({ caseItem, forecast, multipleId }) => {
                const shortfall =
                  getWaterfallCaseYearMultipleValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    multipleId!,
                    (multiple) => multiple.shareholderDebtShortfall
                  ) * -1; // Multiply by -1 to make the value negative

                return <CellValue {...amountRules} value={shortfall} />;
              }}
            />
            <tr>
              <th className="table-primary__cell--header-tertiary">
                {l('_SplitOfShareholderDebtDistributions')}
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
            </tr>
            {totalShareholderDebtDistributionsByOwnerType}
          </>
        )}
      </tbody>
    </>
  );
};
