import { Outlet, RouteObject } from 'react-router-dom';
import ProjectDetails from '@app/modules/projects/inputs/project-admin/ProjectDetails';
import {
  DealThesisForm,
  DealThesisPage,
} from '@app/modules/projects/inputs/deal-thesis/DealThesisPage';
import ProjectRoutesWrapper from '@app/modules/projects/project-routes-wrapper/ProjectRoutesWrapper';
import CapitalStructureSidebar from '@app/shared/layout/capital-structure-sidebar/CapitalStructureSidebar';
import { ContentHeader } from '@app/shared/layout/content-header/ContentHeader';
import { CapitalStructureNavigation } from '@app/modules/projects/inputs/capital-structure/CapitalStructureNavigation';
import { DealThesisPageNavigation } from '@app/modules/projects/inputs/deal-thesis/DealThesisPageNavigation';
import { AddDealThesisCase } from '@app/modules/projects/inputs/deal-thesis/AddDealThesisCase';
import BuildStructureContent from '@app/modules/projects/inputs/capital-structure/tabs/build-structure-tab/BuildStructureContent';
import CapTable from '@app/modules/projects/inputs/capital-structure/tabs/cap-table/CapTable';
import { SourcesAndUsesContent } from '@app/modules/projects/inputs/capital-structure/tabs/sources-and-uses-tab/SourcesAndUsesContent';
import { CostOfEquityNavigation } from './outputs/cost-of-equity/CostOfEquityNavigation';
import SummaryAndHighLevelData from '@app/modules/projects/outputs/cost-of-equity/summary-and-high-level-data/SummaryAndHighLevelData';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { PayoutLogic } from '@app/modules/projects/inputs/capital-structure/tabs/payout-logic/PayoutLogic';
import { PayoutLogicMain } from '@app/modules/projects/inputs/capital-structure/tabs/payout-logic/PayoutLogicMain';
import { ValuationInputsNavigation } from '@app/modules/projects/inputs/valuation/ValuationInputsNavigation';
import { CapmAssumptions } from '@app/modules/projects/inputs/valuation/capm-assumptions/CapmAssumptions';
import MinorityDiscounts from '@app/modules/projects/inputs/valuation/minority-discounts/MinorityDiscounts';
import TotalEquityCrossCheck from '@app/modules/projects/outputs/cost-of-equity/total-equity-cross-check/TotalEquityCrossCheck';
import WaterfallTable from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTable';
import { InstrumentLevelData } from '@app/modules/projects/outputs/cost-of-equity/instrument-level-data/InstrumentLevelData';
import { useAppSelector } from '@core/hooks/redux-hooks';
import Projects from '@app/modules/projects/projects/Projects';
import { EmptyValues } from '@app/shared/constants/empty-values';
import Dashboard from '@app/modules/projects/dashboard/Dashboard';
import DashboardOpm from '@app/modules/projects/dashboard/DashboardOpm';
import RealWorldDistributionsOpm from './outputs/real-world-distributions/RealWorldDistributions';
import { DashboardHeader } from '@app/modules/projects/dashboard/DashboardHeader';
import { ContentHeaderLayout } from '@app/modules/projects/project-routes-wrapper/layout-enums';
import { HeaderVariations } from '@app/shared/layout/content-header/types';
import BenchmarkingInputs from '@app/modules/projects/inputs/benchmarking-inputs/BenchmarkingInputs';
import BenchmarkingInputs2 from '@app/modules/projects/inputs/benchmarking-inputs-2/BenchmarkingInputs2';
import { OpmInputsForm } from '@app/modules/projects/inputs/opm-inputs/OpmInputs';
import { DeleteProjectAction } from '@app/modules/projects/inputs/project-admin/DeleteProjectAction';
import { CopyProjectAction } from '@app/modules/projects/inputs/project-admin/copy-project/CopyProjectAction';
import { ParticipationTable } from '@app/modules/projects/inputs/capital-structure/tabs/participation-table/ParticipationTable';
import store from '@core/store/store';
import { fetchAndRecalculate } from '@core/store/project-slice';
import { LoadingStatus } from '@app/shared/models/loading-status-enum';
import { setHighlightEditableCells } from '@app/core/store/ui-values-slice';
import { ProjectAccessAction } from '@app/modules/projects/inputs/project-admin/project-access/ProjectAccessAction';
import { GlobalHistory } from '@app/shared/components/global-navigate/GlobalNavigate';
import { RouteConstants } from './RouteConstants';
import { OpmWaterfallNavigation } from './outputs/opm-summary-waterfall/OpmWaterfallNavigation';
import FirstLastTable from './outputs/opm-summary-waterfall/first-last-5/FirstLastTable';
import SampleWaterfallTable from './outputs/opm-summary-waterfall/sample-waterfall/SampleWaterfallTable';
import { Pwerm2WaterfallTable } from './outputs/pwerm2-summary-waterfall/Pwerm2WaterfallTable';
import PwermComparator from './outputs/pwerm2-summary-waterfall/PwermComparator';
import Pwerm2Dashboard from './dashboard/Pwerm2Dashboard';
import { CostOfEquityPage } from './outputs/cost-of-equity/CostOfEquityPage';
import CapTable2 from './inputs/capital-structure/tabs/cap-table-2/CapTable2';
import { ParticipationTable2 } from './inputs/capital-structure/tabs/participation-table-2/ParticipationTable2';
import { CapitalStructurePage } from './inputs/capital-structure/CapitalStructurePage';
import { ValuationInputsPage } from './inputs/valuation/ValuationInputsPage';
import { OpmWaterfallPage } from './outputs/opm-summary-waterfall/OpmWaterfallPage';
import { CostOfEquity2Navigation } from './outputs/cost-of-equity-2/CostOfEquity2Navigation';
import { CostOfEquity2Page } from './outputs/cost-of-equity-2/CostOfEquity2Page';
import SummaryAndHighLevelData2 from './outputs/cost-of-equity-2/summary-and-high-level-data/SummaryAndHighLevelData2';
import TotalEquityCrossCheck2 from './outputs/cost-of-equity-2/total-equity-cross-check/TotalEquityCrossCheck2';
import { InstrumentLevelData2 } from './outputs/cost-of-equity-2/instrument-level-data/InstrumentLevelData2';
import SummaryAndHighLevelDataComparator from './outputs/cost-of-equity-2/comparator/SummaryAndHighLevelDataComparator';
import { CostOfEquityComparatorNavigation } from './outputs/cost-of-equity-2/comparator/CostOfEquityComparatorNavigation';
import { CostOfEquityComparator } from './outputs/cost-of-equity-2/comparator/CostOfEquityComparator';
import TotalEquityCrossCheckComparator from './outputs/cost-of-equity-2/comparator/TotalEquityCrossCheckComparator';
import InstrumentLevelDataComparator from './outputs/cost-of-equity-2/comparator/InstrumentLevelDataComparator';
import { DealThesisForm2, DealThesisPage2 } from './inputs/deal-thesis-2/DealThesisPage2';
import CapitalStructureSidebarErf from '@app/shared/layout/capital-structure-sidebar/CapitalStructureSidebarErf';
import BuildStructureContentErf from './inputs/capital-structure/tabs/build-structure-erf/BuildStructureContentErf';
import { AddEvent } from './inputs/capital-structure/tabs/build-structure-erf/add-event/AddEvent';
import { PayoutLogicMainErf } from './inputs/capital-structure/tabs/payout-logic-erf/PayoutLogicMainErf';
import { PayoutLogicErf } from './inputs/capital-structure/tabs/payout-logic-erf/PayoutLogicErf';
import { ParticipationTableErf } from './inputs/capital-structure/tabs/participation-table-erf/ParticipationTableErf';
import CapTableErf from './inputs/capital-structure/tabs/cap-table-erf/CapTableErf';
import { CapitalStructureTabContent } from './inputs/capital-structure/tabs/CapitalStructureTabContent';
import PwermOpmConclusions from './outputs/overall-conclusion/PwermOpmConclusions';

const ProjectName = () => {
  const { name } = useAppSelector((state) => state.project.projectDraft);
  return <>{name ? name : EmptyValues.EnDash}</>;
};

const summaryAndDashboardRoutes: RouteObject[] = [
  {
    path: RouteConstants.PwermOpmConclusions,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.PwermOpmConclusions}
        contentHeader={
          <ContentHeader
            title="Overall Conclusion"
            highlightEditableCellsToggleVisible
            showPerShareFiguresVisible
          />
        }
        pageName={RouteConstants.PwermOpmConclusions}>
        <PwermOpmConclusions />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'Overall Conclusion' },
    },
  },
  {
    path: RouteConstants.DashboardPwerm,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.DashboardPwerm}
        contentHeader={<DashboardHeader isChartScaled />}
        contentHeaderLayout={ContentHeaderLayout.Secondary}
        pageName={RouteConstants.DashboardPwerm}>
        <Dashboard />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'PWERM Dashboard' },
    },
  },
  {
    path: RouteConstants.DashboardOpm,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.DashboardOpm}
        contentHeader={<DashboardHeader isChartScaled />}
        contentHeaderLayout={ContentHeaderLayout.Secondary}
        pageName={RouteConstants.DashboardOpm}>
        <DashboardOpm />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'OPM Dashboard' },
    },
  },
  {
    path: RouteConstants.RealWorldDistributions,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.RealWorldDistributions}
        contentHeader={<DashboardHeader isChartScaled />}
        contentHeaderLayout={ContentHeaderLayout.Secondary}
        pageName={RouteConstants.RealWorldDistributions}>
        <RealWorldDistributionsOpm />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'Real World Distributions' },
    },
  },
];

const inputsRoutes: RouteObject[] = [
  {
    path: RouteConstants.ProjectDetails,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.ProjectDetails}
        contentHeader={
          <ContentHeader
            actions={
              <>
                <ProjectAccessAction standalone /> <CopyProjectAction /> <DeleteProjectAction />
              </>
            }
            title="Project Details"
          />
        }
        pageName={RouteConstants.ProjectDetails}>
        <ProjectDetails />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'Project Details' },
    },
  },
  {
    path: RouteConstants.CapitalStructure,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <CapitalStructurePage />,
      },
      {
        path: RouteConstants.BuildStructure,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.BuildStructure}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
              />
            }
            rightSidebar={<CapitalStructureSidebar />}
            pageName={RouteConstants.BuildStructure}>
            <BuildStructureContent />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.BuildStructureErf,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.BuildStructureErf}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
                actions={<AddEvent />}
              />
            }
            rightSidebar={<CapitalStructureSidebarErf />}
            pageName={RouteConstants.BuildStructureErf}>
            <CapitalStructureTabContent isBuildStructure>
              <Outlet />
            </CapitalStructureTabContent>
          </ProjectRoutesWrapper.PageLayout>
        ),
        children: [
          {
            path: '',
            element: <BuildStructureContentErf />,
          },
          {
            path: ':caseId',
            element: <BuildStructureContentErf />,
          },
        ],
      },
      {
        path: RouteConstants.PayoutLogic,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.PayoutLogic}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
              />
            }
            pageName={RouteConstants.PayoutLogic}>
            <PayoutLogicMain>
              <Outlet />
            </PayoutLogicMain>
          </ProjectRoutesWrapper.PageLayout>
        ),
        children: [
          {
            path: ':instrumentId',
            element: <PayoutLogic />,
          },
        ],
      },
      {
        path: RouteConstants.PayoutLogicErf,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.PayoutLogicErf}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
              />
            }
            pageName={RouteConstants.PayoutLogicErf}>
            <PayoutLogicMainErf>
              <Outlet />
            </PayoutLogicMainErf>
          </ProjectRoutesWrapper.PageLayout>
        ),
        children: [
          {
            path: ':instrumentId',
            element: <PayoutLogicErf />,
          },
        ],
      },
      {
        path: RouteConstants.CapTable,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.CapTable}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
              />
            }
            pageName={RouteConstants.CapTable}>
            <CapTable />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.CapTable2,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.CapTable2}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
              />
            }
            pageName={RouteConstants.CapTable2}>
            <CapTable2 />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.CapTableErf,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.CapTableErf}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
              />
            }
            pageName={RouteConstants.CapTableErf}>
            <CapitalStructureTabContent>
              <Outlet />
            </CapitalStructureTabContent>
          </ProjectRoutesWrapper.PageLayout>
        ),
        children: [
          {
            path: '',
            element: <CapTableErf />,
          },
          {
            path: ':caseId',
            element: <CapTableErf />,
          },
        ],
      },
      {
        path: RouteConstants.ParticipationTable,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.ParticipationTable}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.ParticipationTable}>
            <ParticipationTable />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.ParticipationTable2,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.ParticipationTable2}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.ParticipationTable2}>
            <ParticipationTable2 />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.ParticipationTableErf,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.ParticipationTableErf}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.ParticipationTableErf}>
            <CapitalStructureTabContent>
              <Outlet />
            </CapitalStructureTabContent>
          </ProjectRoutesWrapper.PageLayout>
        ),
        children: [
          {
            path: '',
            element: <ParticipationTableErf />,
          },
          {
            path: ':caseId',
            element: <ParticipationTableErf />,
          },
        ],
      },
      {
        path: RouteConstants.SourcesAndUses,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.SourcesAndUses}
            contentHeader={
              <ContentHeader
                title="Capital Structure"
                navigation={<CapitalStructureNavigation />}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.SourcesAndUses}>
            <SourcesAndUsesContent />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Capital Structure' },
    },
  },
  {
    path: RouteConstants.DealThesis,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <DealThesisPage />,
      },
      {
        path: ':caseId',
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.DealThesis}
            contentHeader={
              <ContentHeader
                title="Deal Thesis"
                actions={<AddDealThesisCase />}
                navigation={<DealThesisPageNavigation />}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.DealThesis}>
            <DealThesisForm />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Deal Thesis' },
    },
  },
  {
    path: RouteConstants.DealThesis2,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <DealThesisPage2 />,
      },
      {
        path: ':caseId',
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.DealThesis2}
            contentHeader={
              <ContentHeader
                title="Deal Thesis 2"
                actions={<AddDealThesisCase />}
                navigation={<DealThesisPageNavigation />}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.DealThesis2}>
            <DealThesisForm2 />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Deal Thesis' },
    },
  },
  {
    path: RouteConstants.ValuationInputs,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ValuationInputsPage />,
      },
      {
        path: RouteConstants.CapmAssumptions,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.CapmAssumptions}
            contentHeader={
              <ContentHeader
                title="Valuation Inputs"
                navigation={<ValuationInputsNavigation />}
                showCurrencyLabel={false}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.CapmAssumptions}>
            <CapmAssumptions />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.MinorityDiscounts,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.MinorityDiscounts}
            contentHeader={
              <ContentHeader
                title="Valuation Inputs"
                navigation={<ValuationInputsNavigation />}
                showCurrencyLabel={false}
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.MinorityDiscounts}>
            <MinorityDiscounts />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Valuation Inputs' },
    },
  },
  {
    path: RouteConstants.BenchmarkingInputs,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.BenchmarkingInputs}
        contentHeader={
          <ContentHeader
            title={<ProjectName />}
            subtitle="Benchmarking Inputs"
            headerVariation={HeaderVariations.DEFAULT_SECONDARY}
            benchmarkingOnlyToggleVisible
          />
        }
        pageName={RouteConstants.BenchmarkingInputs}>
        <BenchmarkingInputs />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'Benchmarking Inputs' },
    },
  },
  {
    path: RouteConstants.BenchmarkingInputs2,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.BenchmarkingInputs2}
        contentHeader={
          <ContentHeader
            title={<ProjectName />}
            subtitle="Benchmarking Inputs 2"
            headerVariation={HeaderVariations.DEFAULT_SECONDARY}
            benchmarkingOnlyToggleVisible
          />
        }
        pageName={RouteConstants.BenchmarkingInputs2}>
        <BenchmarkingInputs2 />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'Benchmarking Inputs' },
    },
  },
  {
    path: RouteConstants.OpmInputs,
    children: [
      {
        index: true,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.OpmInputs}
            contentHeader={
              <ContentHeader
                title="OPM Inputs"
                expandAllToggleVisible
                highlightEditableCellsToggleVisible
              />
            }
            pageName={RouteConstants.OpmInputs}>
            <OpmInputsForm />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'OPM Inputs' },
    },
  },
];

const pwermWaterfallOutputsRoutes: RouteObject[] = [
  {
    path: RouteConstants.CostOfEquity,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <CostOfEquityPage />,
      },
      {
        path: RouteConstants.Summary,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.Summary}
            contentHeader={
              <ContentHeader title="Cost of Equity" navigation={<CostOfEquityNavigation />} />
            }
            pageName={RouteConstants.Summary}>
            <SummaryAndHighLevelData />,
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.TotalEquityCrossCheck,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.TotalEquityCrossCheck}
            contentHeader={
              <ContentHeader title="Cost of Equity" navigation={<CostOfEquityNavigation />} />
            }
            pageName={RouteConstants.TotalEquityCrossCheck}>
            <TotalEquityCrossCheck />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.InstrumentById,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.InstrumentById}
            contentHeader={
              <ContentHeader title="Cost of Equity" navigation={<CostOfEquityNavigation />} />
            }
            pageName={RouteConstants.InstrumentById}>
            <InstrumentLevelData />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Cost of Equity' },
    },
  },
  {
    path: RouteConstants.CostOfEquity2,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <CostOfEquity2Page />,
      },
      {
        path: RouteConstants.Summary2,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.Summary2}
            contentHeader={
              <ContentHeader title="Cost of Equity 2" navigation={<CostOfEquity2Navigation />} />
            }
            pageName={RouteConstants.Summary2}>
            <SummaryAndHighLevelData2 />,
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.TotalEquityCrossCheck2,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.TotalEquityCrossCheck2}
            contentHeader={
              <ContentHeader title="Cost of Equity 2" navigation={<CostOfEquity2Navigation />} />
            }
            pageName={RouteConstants.TotalEquityCrossCheck2}>
            <TotalEquityCrossCheck2 />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.InstrumentById2,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.InstrumentById2}
            contentHeader={
              <ContentHeader title="Cost of Equity 2" navigation={<CostOfEquity2Navigation />} />
            }
            pageName={RouteConstants.InstrumentById2}>
            <InstrumentLevelData2 />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Cost of Equity 2' },
    },
  },
  {
    path: RouteConstants.CostOfEquityComparator,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <CostOfEquityComparator />,
      },
      {
        path: RouteConstants.SummaryComparator,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.SummaryComparator}
            contentHeader={
              <ContentHeader
                title="Cost of Equity Comparator"
                navigation={<CostOfEquityComparatorNavigation />}
              />
            }
            pageName={RouteConstants.SummaryComparator}>
            <SummaryAndHighLevelDataComparator />,
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.TotalEquityCrossCheckComparator,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.TotalEquityCrossCheckComparator}
            contentHeader={
              <ContentHeader
                title="Cost of Equity 2"
                navigation={<CostOfEquityComparatorNavigation />}
              />
            }
            pageName={RouteConstants.TotalEquityCrossCheckComparator}>
            <TotalEquityCrossCheckComparator />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.InstrumentByIdComparator,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.InstrumentByIdComparator}
            contentHeader={
              <ContentHeader
                title="Cost of Equity 2"
                navigation={<CostOfEquityComparatorNavigation />}
              />
            }
            pageName={RouteConstants.InstrumentByIdComparator}>
            <InstrumentLevelDataComparator />
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'Cost of Equity 2' },
    },
  },
  {
    path: RouteConstants.PwermWaterfall,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.PwermWaterfall}
        contentHeader={<ContentHeader title="Waterfall PWERM" showCurrencyLabel={false} />}
        pageName={RouteConstants.PwermWaterfall}>
        <WaterfallTable />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'PWERM Waterfall' },
    },
  },
];

const pwerm2WaterfallOutputsRoutes: RouteObject[] = [
  {
    path: RouteConstants.Pwerm2Waterfall,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.Pwerm2Waterfall}
        contentHeader={<ContentHeader title="Waterfall PWERM 2" showCurrencyLabel={false} />}
        pageName={RouteConstants.Pwerm2Waterfall}>
        <Pwerm2WaterfallTable />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'PWERM 2 Waterfall' },
    },
  },
  {
    path: RouteConstants.PwermComparator,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.PwermComparator}
        contentHeader={<ContentHeader title="Waterfall Comparator" showCurrencyLabel={false} />}
        pageName={RouteConstants.PwermComparator}>
        <PwermComparator />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'PWERM Comparator' },
    },
  },
];

const pwerm2DashboardRoutes: RouteObject[] = [
  {
    path: RouteConstants.DashboardPwerm2,
    element: (
      <ProjectRoutesWrapper.PageLayout
        key={RouteConstants.DashboardPwerm2}
        contentHeader={<DashboardHeader />}
        contentHeaderLayout={ContentHeaderLayout.Secondary}
        pageName={RouteConstants.DashboardPwerm2}>
        <Pwerm2Dashboard />
      </ProjectRoutesWrapper.PageLayout>
    ),
    handle: {
      crumb: { title: () => 'PWERM 2 Dashboard' },
    },
  },
];

const opmWaterfallOutputsRoutes: RouteObject[] = [
  {
    path: RouteConstants.OpmWaterfall,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <OpmWaterfallPage />,
      },
      {
        path: RouteConstants.FirstLastSimulations,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.FirstLastSimulations}
            contentHeader={
              <ContentHeader title="Waterfall OPM" navigation={<OpmWaterfallNavigation />} />
            }
            pageName={RouteConstants.FirstLastSimulations}>
            <FirstLastTable />,
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
      {
        path: RouteConstants.SampleWaterfall,
        element: (
          <ProjectRoutesWrapper.PageLayout
            key={RouteConstants.SampleWaterfall}
            contentHeader={
              <ContentHeader title="Waterfall OPM" navigation={<OpmWaterfallNavigation />} />
            }
            pageName={RouteConstants.SampleWaterfall}>
            <SampleWaterfallTable />,
          </ProjectRoutesWrapper.PageLayout>
        ),
      },
    ],
    handle: {
      crumb: { title: () => 'OPM Waterfall' },
    },
  },
];

const projectsRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <>
        <GlobalHistory />
        <Projects />
      </>
    ),
  },
  {
    path: ':projectId',
    element: (
      <ProjectRoutesWrapper>
        <Outlet />
      </ProjectRoutesWrapper>
    ),
    loader: async ({ params }) => {
      const projectId = params?.projectId;
      const project = store.getState().project;
      if (projectId !== project?.projectDraft?.id?.toString()) {
        if (project.status === LoadingStatus.Idle || project.status === LoadingStatus.Failed) {
          store.dispatch(setHighlightEditableCells(false));
          return store.dispatch(fetchAndRecalculate(Number(projectId)));
        }
      }

      return null;
    },
    children: [
      { index: true, element: <PwcNavigate route={RouteConstants.DashboardPwerm} /> },
      ...summaryAndDashboardRoutes,
      ...pwerm2DashboardRoutes,
      ...inputsRoutes,
      ...pwermWaterfallOutputsRoutes,
      ...pwerm2WaterfallOutputsRoutes,
      ...opmWaterfallOutputsRoutes,
    ],
    handle: {
      crumb: {
        title: () => <ProjectName />,
      },
    },
  },
];

export default projectsRoutes;
